import { ChangeEvent, FC } from 'react';

import { useTranslation } from 'react-i18next';

import { ProviderEnum, SocialSignInProviderEnum } from '@dynamic-labs/sdk-api';
import { SingleToggleCard } from '@dynamic-labs/northstar';
import { SocialIcon } from '@dynamic-labs/iconic';

import { useProvidersContext } from '../../../../../../../app/context/ProvidersContext';
import { AdminIcon } from '../../../../../../../icons';
import styles from '../../BrandedWalletsSection/MultiWallet/MultiWallet.module.scss';
import { useModalHashLocation } from '../../../../../../../app/routes/Configurations/utils';
import { SideDrawer } from '../../../../../../components/SideDrawer';
import { getProviderDisplayName } from '../../../../../../../app/utils/getProviderDisplayName';
import { useSignInWithSocialProviders } from '../../../../../../../app/routes/Configurations/Providers/hooks/useSignInWithSocialProviders';
import { useSettingsContext } from '../../../../../../../app/context/SettingsContext';
import { useEnvironmentsContext } from '../../../../../../../app/context/EnvironmentsContext';
import { getToggleBanners } from '../../shared/getToggleBanners';

import { SocialDrawerContent } from './SocialDrawerContent';

type SocialToggleProps = {
  disabled: boolean;
  provider: ProviderEnum;
};

export const SocialToggle: FC<SocialToggleProps> = ({ provider, disabled }) => {
  const {
    availableSocialProviders,
    getProviderValue,
    hasProviderChanged,
    toggleProvider,
    enabledSocialProviders,
  } = useProvidersContext();
  const {
    enabledProviders,
    initialProviders,
    listOfProviders,
    toggleSocialSignInProvider,
  } = useSignInWithSocialProviders({
    disabled: false,
  });
  const { settings, setSettings } = useSettingsContext();
  const { activeEnvironmentType } = useEnvironmentsContext();

  if (!availableSocialProviders.includes(provider)) {
    throw Error(`Provider ${provider} is not available`);
  }

  const { showModal, handleShowModal } = useModalHashLocation({
    currentHash: `social-${provider}`,
  });

  const { t } = useTranslation();

  const isSocialLinkingProviderEnabled = Boolean(
    getProviderValue(provider, 'enabledAt'),
  );
  const hasSocialProviderChanged = hasProviderChanged(provider);

  const providerAsSignupProvider =
    provider as unknown as SocialSignInProviderEnum;
  const isSignInWithProviderEnabled = enabledProviders.includes(
    providerAsSignupProvider,
  );
  const isSocialSignInEnabledStateSaved =
    initialProviders.includes(providerAsSignupProvider) ===
      isSignInWithProviderEnabled ||
    !listOfProviders.includes(providerAsSignupProvider) ===
      !isSignInWithProviderEnabled;

  const allowDrawerToggle =
    (isSocialLinkingProviderEnabled || isSignInWithProviderEnabled) &&
    !disabled;

  const isInstagram = provider === ProviderEnum.Instagram;

  const handleToggleSocial = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      // when toggling provider on, enable social kyc field
      const updatedKyc = settings[activeEnvironmentType].kyc.map((field) => {
        if (field.name === 'social') {
          return {
            ...field,
            enabled: true,
          };
        }

        return field;
      });

      setSettings({
        ...settings,
        [activeEnvironmentType]: {
          ...settings[activeEnvironmentType],
          kyc: updatedKyc,
        },
      });

      toggleProvider(provider, true);
      if (!isInstagram) {
        toggleSocialSignInProvider(providerAsSignupProvider, true);
      }

      return;
    }

    // check if there is any other linking social provider enabled
    const anyLinkingProvidersEnabked = enabledSocialProviders.length > 1;

    // when toggling provider off, disable social kyc field if there is no other social provider enabled
    if (!anyLinkingProvidersEnabked) {
      const updatedKyc = settings[activeEnvironmentType].kyc.map((field) => {
        if (field.name === 'social') {
          return {
            ...field,
            enabled: false,
          };
        }

        return field;
      });

      setSettings({
        ...settings,
        [activeEnvironmentType]: {
          ...settings[activeEnvironmentType],
          kyc: updatedKyc,
        },
      });
    }

    toggleProvider(provider, false);
    toggleSocialSignInProvider(providerAsSignupProvider, false);
  };

  return (
    <>
      <SingleToggleCard
        accordionKey={`${provider}-toggle`}
        title={getProviderDisplayName(provider)}
        allowExpand={false}
        onCardClick={
          allowDrawerToggle ? () => handleShowModal(true) : undefined
        }
        Tags={getToggleBanners({
          enabled: allowDrawerToggle,
          useForLinking: isSocialLinkingProviderEnabled,
          useForLogIn: isSignInWithProviderEnabled,
        })}
        inputProps={{
          checked: allowDrawerToggle,
          disabled,
          id: `${provider}-toggle`,
          isSaved: !hasSocialProviderChanged && isSocialSignInEnabledStateSaved,
          onChange: handleToggleSocial,
          type: 'toggle',
        }}
        Icon={<SocialIcon name={provider} />}
        customActionIcon={{
          Icon: <AdminIcon />,
          className: styles['action-icon'],
        }}
      />

      <SideDrawer
        handleClose={() => handleShowModal(false)}
        isOpen={showModal && !disabled}
        title={t('v2.page.log_in_methods.social.drawer.title', {
          interpolation: { escapeValue: false },
          provider: getProviderDisplayName(provider),
        })}
        Icon={<SocialIcon name={provider} />}
        className={styles.drawer}
      >
        <div className={styles.container}>
          <SocialDrawerContent provider={provider} />
        </div>
      </SideDrawer>
    </>
  );
};
