import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { RoleEnum } from '@dynamic-labs/sdk-api';

import App from '../../app/app';
import { ROUTES } from '../../app/components/Navigation/data';
import { ProtectedRoute } from '../../app/components/ProtectedRoute';
import { EventsContextProvider } from '../../app/context/EventsContext';
import { WebhooksContextProvider } from '../../app/context/WebhooksContext';
import AdminRoute from '../../app/routes/Admin';
import { Analytics } from '../../app/routes/Analytics';
import { ChainsAndNetworksRoute } from '../../app/routes/ChainsAndNetworks';
import ConfigurationsRoute from '../../app/routes/Configurations';
import { ModalRoute } from '../../app/routes/Design/ModalRoute';
import {
  ApiRoute,
  ThirdPartyAuth,
  WebhookRoute,
} from '../../app/routes/Developer';
import { Home } from '../../app/routes/Home';
import { CoinbaseWaasKeyExport } from '../../app/routes/KeyExport';
import { OnboardingRoute } from '../../app/routes/OnBoarding';
import { OverviewRoute } from '../../app/routes/Overview';
import { SDKOAuth } from '../../app/routes/SDKOAuth';
import {
  General,
  Security as SettingsSecurity,
} from '../../app/routes/Settings';
import { Privacy } from '../../app/routes/Settings/Privacy';
import { UsersRoute, VisitorsRoute } from '../../app/routes/Users';
import DocumentationRoute from '../../app/routes/documentation-route';
import { Dashboard, EmbeddedWalletsPage, Security } from '../routes';
import { LogInMethodsPage } from '../routes/Dashboard/LogInMethodsPage';

/* eslint react/jsx-wrap-multilines: 0 */
export const DashboardV2Router = () => (
  <BrowserRouter>
    <App>
      <Routes>
        <Route path={ROUTES.home} element={<Home />} />
        <Route path={ROUTES.sdkOAuth} element={<SDKOAuth />} />
        <Route
          path={ROUTES.coinbaseWaasKeyExport}
          element={<CoinbaseWaasKeyExport />}
        />

        <Route element={<Dashboard />}>
          {/* Viewer Routes */}
          <Route
            path={ROUTES.overview}
            element={
              <ProtectedRoute role={RoleEnum.Viewer}>
                <OverviewRoute />
              </ProtectedRoute>
            }
          />

          <Route
            path={ROUTES.onboarding}
            element={
              <ProtectedRoute role={RoleEnum.Viewer}>
                <OnboardingRoute />
              </ProtectedRoute>
            }
          />

          <Route
            path={ROUTES.analytics}
            element={
              <ProtectedRoute role={RoleEnum.Viewer}>
                <Analytics />
              </ProtectedRoute>
            }
          />

          <Route
            path={ROUTES.documentation}
            element={
              <ProtectedRoute role={RoleEnum.Viewer}>
                <DocumentationRoute />
              </ProtectedRoute>
            }
          />

          <Route path={ROUTES.users}>
            <Route
              path={ROUTES.usersAuthenticated}
              element={
                <ProtectedRoute role={RoleEnum.Viewer}>
                  <UsersRoute />
                </ProtectedRoute>
              }
            />
            <Route
              path={ROUTES.usersVisitors}
              element={
                <ProtectedRoute role={RoleEnum.Viewer}>
                  <VisitorsRoute />
                </ProtectedRoute>
              }
            />
          </Route>

          {/* Developer Routes */}
          <Route path={ROUTES.developer}>
            <Route
              path={ROUTES.developerApi}
              element={
                <ProtectedRoute role={RoleEnum.Developer}>
                  <ApiRoute />
                </ProtectedRoute>
              }
            />
            <Route
              path={ROUTES.developerWebhooks}
              element={
                <ProtectedRoute role={RoleEnum.Developer}>
                  <WebhooksContextProvider>
                    <EventsContextProvider>
                      <WebhookRoute />
                    </EventsContextProvider>
                  </WebhooksContextProvider>
                </ProtectedRoute>
              }
            />
            <Route
              path={ROUTES.developerThirdPartyAuth}
              element={
                <ProtectedRoute role={RoleEnum.Developer}>
                  <ThirdPartyAuth />
                </ProtectedRoute>
              }
            />
          </Route>

          <Route
            path={ROUTES.configurations}
            element={
              <ProtectedRoute role={RoleEnum.Developer}>
                <ConfigurationsRoute />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.chainsAndNetworks}
            element={
              <ProtectedRoute role={RoleEnum.Developer}>
                <ChainsAndNetworksRoute />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.security}
            element={
              <ProtectedRoute role={RoleEnum.Developer}>
                <Security />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.plugins}
            element={
              <ProtectedRoute role={RoleEnum.Developer}>
                <ConfigurationsRoute />
              </ProtectedRoute>
            }
          />

          <Route
            path={ROUTES.logInMethods}
            element={
              <ProtectedRoute role={RoleEnum.Developer}>
                <LogInMethodsPage />
              </ProtectedRoute>
            }
          />

          <Route
            path={ROUTES.embeddedWallets}
            element={
              <ProtectedRoute role={RoleEnum.Developer}>
                <EmbeddedWalletsPage />
              </ProtectedRoute>
            }
          >
            <Route
              path={ROUTES.embeddedWalletsDynamic}
              element={
                <ProtectedRoute role={RoleEnum.Developer}>
                  <EmbeddedWalletsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={ROUTES.embeddedWalletsThirdParty}
              element={
                <ProtectedRoute role={RoleEnum.Developer}>
                  <EmbeddedWalletsPage />
                </ProtectedRoute>
              }
            />
          </Route>

          <Route
            path={ROUTES.design}
            element={
              <ProtectedRoute role={RoleEnum.Developer}>
                <ModalRoute />
              </ProtectedRoute>
            }
          />

          <Route path={ROUTES.settings}>
            <Route
              path={ROUTES.settingsSecurity}
              element={
                <ProtectedRoute role={RoleEnum.Developer}>
                  <SettingsSecurity />
                </ProtectedRoute>
              }
            />
            <Route
              path={ROUTES.settingsPrivacy}
              element={
                <ProtectedRoute role={RoleEnum.Developer}>
                  <Privacy />
                </ProtectedRoute>
              }
            />
            <Route
              path={ROUTES.settingsGeneral}
              element={
                <ProtectedRoute role={RoleEnum.Developer}>
                  <General />
                </ProtectedRoute>
              }
            />
          </Route>

          {/* Admin/Owner Routes */}
          <Route
            path={ROUTES.admin}
            element={
              <ProtectedRoute role={RoleEnum.Admin}>
                <AdminRoute />
              </ProtectedRoute>
            }
          />

          {/* Redirects */}
          <Route
            path={ROUTES.api}
            element={<Navigate to={ROUTES.developer} />}
          />
        </Route>
        {/* 404 Route - redirect to home page */}
        <Route path='*' element={<Home />} />
      </Routes>
    </App>
  </BrowserRouter>
);
