import { FC } from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';

import { SidebarNavigation } from '@dynamic-labs/northstar';

import { useDashboardRole } from '../../../app/hooks/useDashboardRole';
import { useSaveBannerContext } from '../../contexts/SaveBannerContext';

import { navigationItems } from './navigationItems';

type NavigationProps = {
  hideNavigation?: boolean;
};

export const Navigation: FC<NavigationProps> = ({ hideNavigation = false }) => {
  const { checkForAccess } = useDashboardRole();
  const { shouldShowSaveBanner, setShouldWaveSaveBanner } =
    useSaveBannerContext();
  const featureFlags = useFlags();

  const filteredNavigationItemsByRole = navigationItems.map((section) => ({
    ...section,
    items: section.items.filter((item) => {
      if (!checkForAccess(item.minRole)) return false;

      if (
        item.hideBehindFeatureFlagKey &&
        !featureFlags[item.hideBehindFeatureFlagKey]
      )
        return false;

      return true;
    }),
  }));

  const filteredNavigationSubItemsByRole = filteredNavigationItemsByRole.map(
    (section) => ({
      ...section,
      items: section.items.map((item) => ({
        ...item,
        subItems: item.subItems?.filter((subItem) =>
          checkForAccess(subItem.minRole),
        ),
      })),
    }),
  );

  if (hideNavigation) {
    return null;
  }

  const handleDisabledNavigationClick = () => {
    if (shouldShowSaveBanner) {
      setShouldWaveSaveBanner(true);
    }
  };

  return (
    <SidebarNavigation
      items={filteredNavigationSubItemsByRole}
      disabled={shouldShowSaveBanner}
      onDisabledClick={handleDisabledNavigationClick}
    />
  );
};
