import React from 'react';

import { Trans } from 'react-i18next';

import Button from '../Button';
import { ButtonVariants } from '../Button/Button.types';
import Portal from '../Portal';
import { Typography } from '../Typography';

import styles from './ConfirmModal.module.css';

export type Props = {
  backdropClassName?: string;
  cancelText: string;
  className?: string;
  confirmationBtnVariant?: ButtonVariants;
  confirmationText?: string;
  loading?: boolean;
  message: string;
  onCancel: VoidFunction;
  onConfirm?: VoidFunction;
  title: string;
};

export const ConfirmModal = ({
  message,
  onConfirm,
  onCancel,
  loading,
  cancelText,
  confirmationText,
  confirmationBtnVariant = 'delete',
  className = '',
  backdropClassName,
  title,
}: Props) => (
  <Portal
    className={`${styles.container} ${className}`}
    handleClose={onCancel}
    backdropClassName={backdropClassName}
    rootId='confirm_modal_root'
  >
    <div className={styles.content}>
      <Typography as='h2' variant='subtitle' className={styles.title}>
        {title}
      </Typography>

      <Typography as='p' variant='paragraph-3' className={styles.message}>
        <Trans>{message}</Trans>
      </Typography>
      <div className={styles.cta}>
        <Button
          testId='cancel'
          className={styles.cancel}
          variant='secondary'
          onClick={onCancel}
          large
        >
          {cancelText}
        </Button>
        {confirmationText && onConfirm && (
          <Button
            loading={loading}
            testId='confirm'
            variant={confirmationBtnVariant}
            onClick={onConfirm}
            large
          >
            {confirmationText}
          </Button>
        )}
      </div>
    </div>
  </Portal>
);
