import {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { Trans, useTranslation } from 'react-i18next';
import { AnimatePresence } from 'framer-motion';
import { useFlags } from 'launchdarkly-react-client-sdk';
import isEqual from 'react-fast-compare';
import { useMutation } from 'react-query';

import { EnvironmentEnum, Provider, ProviderEnum } from '@dynamic-labs/sdk-api';

import { environmentsApi } from '../../../../services/api';
import { useSettingsHasChanged } from '../../../../hooks/useSettingsHasChanged';
import { useEnvironmentsContext } from '../../../../context/EnvironmentsContext';
import { useSettingsContext } from '../../../../context/SettingsContext';
import { RadioButton } from '../../../../components/RadioButton';
import { isProviderEnabled } from '../../../../utils/isProviderEnabled';
import { ErrorInfo } from '../../../../components/ErrorInfo';
import { ConfirmToast } from '../../../../components/ConfirmToast';
import { useSaveProvidersMutation } from '../../../../hooks/useSaveProvidersMutation';
import { useEnvironmentProviders } from '../../../../hooks/useEnvironmentProviders';
import {
  AccountAbstractionCircularIcon,
  AlchemyIcon,
  ZeroDevIcon,
} from '../../../../../icons';
import { EnvironmentTabsLayout } from '../../../../layouts/EnvironmentTabsLayout';
import { SideModalHeader } from '../../../../components/SideModalHeader';
import { Toggle } from '../../../../components/Toggle';
import { Typography } from '../../../../components/Typography';
import Input from '../../../../components/Input';
import Portal from '../../../../components/Portal';
import { useEnvironmentId } from '../../Providers/hooks';
import { didProviderChange } from '../utils/didProviderChange';
import { getProviderInProvidersResponse } from '../../utils';

import { ProviderControlTemplate } from './components';

const USERS_SETTING = 'users';
const WALLETS_SETTING = 'wallets';

type AccountAbstractionModalProps = {
  onClickClose: () => void;
};
export const AccountAbstractionModal: FC<AccountAbstractionModalProps> = ({
  onClickClose,
}) => {
  const { t } = useTranslation();
  const environmentId = useEnvironmentId();
  const { enableAlchemyAccountAbstractionIntegration } = useFlags();

  const { data: providersData } = useEnvironmentProviders(environmentId);

  const { activeEnvironmentType } = useEnvironmentsContext();

  const { setSettings, settings, initialSettings } = useSettingsContext();

  const {
    cancelChanges: cancelProjectChanges,
    updateInitialSettings: updateProjectInitialSettings,
  } = useSettingsHasChanged('sdk');

  const canEnableAccountAbstractionProviders = useMemo(() => {
    if (!providersData) return false;

    const { providers } = providersData;

    if (!providers) return false;

    return (
      isProviderEnabled(providers, ProviderEnum.MagicLink) ||
      isProviderEnabled(providers, ProviderEnum.Turnkey) ||
      isProviderEnabled(providers, ProviderEnum.CoinbaseWaas)
    );
  }, [providersData]);

  const savedAlchemyProvider = getProviderInProvidersResponse(
    providersData,
    ProviderEnum.Alchemy,
  );

  const savedZeroDevProvider = getProviderInProvidersResponse(
    providersData,
    ProviderEnum.Zerodev,
  );

  const [alchemyProvider, setAlchemyProvider] =
    useState<Provider>(savedAlchemyProvider);

  const [zeroDevProvider, setZeroDevProvider] =
    useState<Provider>(savedZeroDevProvider);

  const onClickCancelChanges = () => {
    setZeroDevProvider(savedZeroDevProvider);
    setAlchemyProvider(savedAlchemyProvider);
    cancelProjectChanges();
  };

  /**
   * Reset the state when the environmentId changes
   */
  useEffect(() => {
    onClickCancelChanges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environmentId]);

  const didAlchemyProviderChanged = didProviderChange(
    alchemyProvider,
    savedAlchemyProvider,
  );

  const didZeroDevProviderChanged = didProviderChange(
    zeroDevProvider,
    savedZeroDevProvider,
  );

  const settingsHasChanged = !isEqual(
    initialSettings[activeEnvironmentType].sdk.accountAbstraction,
    settings[activeEnvironmentType].sdk.accountAbstraction,
  );

  const hasChanges =
    didAlchemyProviderChanged ||
    didZeroDevProviderChanged ||
    settingsHasChanged;
  const hasMultipleProvidersEnabled = Boolean(
    alchemyProvider?.enabledAt && zeroDevProvider?.enabledAt,
  );

  const { mutate: saveProviders, isLoading: isSavingProviders } =
    useSaveProvidersMutation();

  const { mutate: saveProjectSettings, isLoading: isSavingProjectSettings } =
    useMutation(
      async () =>
        environmentId &&
        environmentsApi.updateProjectSettings({
          environmentId,
          projectSettings: settings[activeEnvironmentType],
        }),
      {
        onSuccess: () => {
          updateProjectInitialSettings(
            activeEnvironmentType as EnvironmentEnum,
          );
        },
      },
    );

  const isLoading = isSavingProviders || isSavingProjectSettings;

  const onClickSave = () => {
    if (hasMultipleProvidersEnabled) return;

    saveProviders([alchemyProvider, zeroDevProvider]);
    saveProjectSettings();
  };

  const handleOnChangeClientId = (
    setState: Dispatch<SetStateAction<Provider>>,
    event: ChangeEvent<HTMLInputElement>,
  ) =>
    setState((prev) => ({
      ...prev,
      clientId: event.target.value,
    }));

  const handleOnToggleEnabledAt = (
    setState: Dispatch<SetStateAction<Provider>>,
  ) =>
    setState((prev) => ({
      ...prev,
      enabledAt: prev?.enabledAt
        ? null
        : savedAlchemyProvider?.enabledAt || new Date(),
    }));

  const handleAASetting = (event: React.ChangeEvent<HTMLInputElement>) => {
    let updatedSetting = {};

    if (event.target.name === USERS_SETTING) {
      updatedSetting = {
        allUsers: event.target.value === 'all',
      };
    }

    if (event.target.name === WALLETS_SETTING) {
      updatedSetting = {
        allWallets: event.target.value === 'all',
      };
    }

    setSettings({
      ...settings,
      [activeEnvironmentType]: {
        ...settings[activeEnvironmentType],
        sdk: {
          ...settings[activeEnvironmentType].sdk,
          accountAbstraction: {
            ...settings[activeEnvironmentType].sdk.accountAbstraction,
            ...updatedSetting,
          },
        },
      },
    });
  };

  return (
    <Portal
      className='absolute w-2/3 bg-white h-screen right-0 top-0 px-8 pt-[60px] pb-24'
      handleClose={onClickClose}
    >
      <EnvironmentTabsLayout className='!left-0 !w-full'>
        <SideModalHeader
          Icon={AccountAbstractionCircularIcon}
          title={t('integrations.wallets.account_abstraction.name')}
          content={t('integrations.wallets.account_abstraction.full_content')}
          showClose
          onClose={onClickClose}
        />

        {hasMultipleProvidersEnabled && (
          <ErrorInfo
            className='mb-3'
            message={t(
              'integrations.wallets.account_abstraction.errors.no_multiple_provider_enabled',
            )}
          />
        )}

        <div className='flex flex-col gap-6 pt-2'>
          {enableAlchemyAccountAbstractionIntegration && (
            <ProviderControlTemplate
              icon={<AlchemyIcon />}
              title={t('integrations.wallets.account_abstraction.alchemy.name')}
              subTitle={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Trans i18nKey='integrations.wallets.account_abstraction.alchemy.docs'>
                  {'You will need to create an account with '}
                  <a
                    className='font-bold text-primary-1'
                    href='https://alchemy.com/'
                    target='_blank'
                    rel='noreferrer'
                  >
                    Alchemy
                  </a>
                  . Enter your App ID here and we&apos;ll handle the rest.
                </Trans>
              }
              toggle={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Toggle
                  withIcon
                  id='alchemy'
                  checked={Boolean(alchemyProvider?.enabledAt)}
                  variant={
                    savedAlchemyProvider.enabledAt ? 'success' : 'primary'
                  }
                  handleChange={() =>
                    handleOnToggleEnabledAt(setAlchemyProvider)
                  }
                  ariaLabel={t(
                    'integrations.wallets.account_abstraction.alchemy.toggle_aria_label',
                  )}
                  disabled={!canEnableAccountAbstractionProviders}
                />
              }
              inputs={[
                <Input
                  name='alchemy-project-id-input'
                  id='alchemy-project-id-input'
                  label={t(
                    'integrations.wallets.account_abstraction.alchemy.provider_id_field.label',
                  )}
                  value={alchemyProvider?.clientId || ''}
                  disableBottomMargin
                  onChange={(event) =>
                    handleOnChangeClientId(setAlchemyProvider, event)
                  }
                  disabled={!canEnableAccountAbstractionProviders}
                />,
              ]}
            />
          )}

          <ProviderControlTemplate
            icon={<ZeroDevIcon />}
            title={t('integrations.wallets.account_abstraction.zero_dev.name')}
            subTitle={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <Trans
                i18nKey='integrations.wallets.account_abstraction.zero_dev.docs'
                components={{
                  docsLink: (
                    <a
                      className='font-bold text-primary-1'
                      href='https://docs.dynamic.xyz/embedded-wallets/add-account-abstraction'
                      target='_blank'
                      rel='noreferrer'
                    >
                      docs
                    </a>
                  ),
                  zerodevLink: (
                    <a
                      className='font-bold text-primary-1'
                      href='https://zerodev.app/'
                      target='_blank'
                      rel='noreferrer'
                    >
                      Zerodev
                    </a>
                  ),
                }}
              />
            }
            toggle={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <Toggle
                ariaLabel={t(
                  'integrations.wallets.account_abstraction.zero_dev.toggle_aria_label',
                )}
                withIcon
                id='zero-dev'
                checked={Boolean(zeroDevProvider.enabledAt)}
                variant={savedZeroDevProvider.enabledAt ? 'success' : 'primary'}
                handleChange={() => handleOnToggleEnabledAt(setZeroDevProvider)}
                disabled={!canEnableAccountAbstractionProviders}
              />
            }
            inputs={[
              <Input
                name='zero-dev-project-id-input'
                id='zero-dev-project-id-input'
                label={t(
                  'integrations.wallets.account_abstraction.zero_dev.provider_id_field.label',
                )}
                disableBottomMargin
                value={zeroDevProvider.clientId || ''}
                onChange={(event) =>
                  handleOnChangeClientId(setZeroDevProvider, event)
                }
                disabled={!canEnableAccountAbstractionProviders}
              />,
            ]}
          />

          <div className='flex flex-col gap-3'>
            <Typography variant='paragraph-3' weight='medium'>
              {t('integrations.wallets.account_abstraction.settings.title')}
            </Typography>
            <div className='flex flex-col divide-y gap-4'>
              <div className='flex flex-col pt-4 gap-2'>
                <RadioButton
                  name={USERS_SETTING}
                  id='new-users-only'
                  label={t(
                    'integrations.wallets.account_abstraction.settings.new_users_only.label',
                  )}
                  description={t(
                    'integrations.wallets.account_abstraction.settings.new_users_only.description',
                  )}
                  value='new'
                  checked={
                    !settings[activeEnvironmentType].sdk.accountAbstraction
                      ?.allUsers
                  }
                  onChange={handleAASetting}
                />

                <RadioButton
                  name={USERS_SETTING}
                  id='all-users'
                  label={t(
                    'integrations.wallets.account_abstraction.settings.all_users.label',
                  )}
                  description={t(
                    'integrations.wallets.account_abstraction.settings.all_users.description',
                  )}
                  value='all'
                  checked={
                    settings[activeEnvironmentType].sdk.accountAbstraction
                      ?.allUsers ?? false
                  }
                  onChange={handleAASetting}
                />
              </div>

              <div className='flex flex-col pt-4 gap-2'>
                <RadioButton
                  name={WALLETS_SETTING}
                  id='embbeded-wallets-only'
                  label={t(
                    'integrations.wallets.account_abstraction.settings.embedded_wallets_only.label',
                  )}
                  description={t(
                    'integrations.wallets.account_abstraction.settings.embedded_wallets_only.description',
                  )}
                  value='embedded'
                  checked={
                    !settings[activeEnvironmentType].sdk.accountAbstraction
                      ?.allWallets
                  }
                  onChange={handleAASetting}
                />

                <RadioButton
                  name={WALLETS_SETTING}
                  id='all-wallets'
                  label={t(
                    'integrations.wallets.account_abstraction.settings.all_wallets.label',
                  )}
                  description={t(
                    'integrations.wallets.account_abstraction.settings.all_wallets.description',
                  )}
                  value='all'
                  checked={
                    settings[activeEnvironmentType].sdk.accountAbstraction
                      ?.allWallets ?? false
                  }
                  onChange={handleAASetting}
                />
              </div>
            </div>
          </div>
        </div>

        <AnimatePresence>
          {(hasChanges || isLoading) && !hasMultipleProvidersEnabled && (
            <ConfirmToast
              confirmationText={t('design_page.confirmButton')}
              cancelText={t('design_page.cancelButton')}
              message={t('design_page.confirmMessage')}
              onConfirm={onClickSave}
              loading={isLoading}
              onCancel={onClickCancelChanges}
              className='!w-10/12 !max-w-max'
            />
          )}
        </AnimatePresence>
      </EnvironmentTabsLayout>
    </Portal>
  );
};
