import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { Input, Typography } from '@dynamic-labs/northstar';
import { CustomFieldRequest } from '@dynamic-labs/sdk-api';

import styles from './CheckboxCustomField.module.scss';

type CheckboxCustomFieldProps = {
  activateErrors: boolean;
  disabled: boolean;
  field: CustomFieldRequest;
  onCheckboxTextChange: (checkboxText: string) => void;
};

export const CheckboxCustomField: FC<CheckboxCustomFieldProps> = ({
  field,
  disabled,
  onCheckboxTextChange,
  activateErrors,
}) => {
  const { t } = useTranslation();

  const handleCheckboxTextChange = (value: string) => {
    onCheckboxTextChange(value);
  };

  return (
    <div className={styles.container}>
      <Typography variant='paragraph-3' weight='medium' color='gray-1'>
        {t(
          'v2.page.log_in_methods.kyc.custom_fields.side_drawer.checkbox.title',
        )}
      </Typography>
      <Input
        name='checkboxText'
        label={
          field.validationRules?.checkboxText === undefined ||
          field.validationRules?.checkboxText === ''
            ? t(
                'v2.page.log_in_methods.kyc.custom_fields.side_drawer.checkbox.label',
              )
            : field.validationRules?.checkboxText
        }
        value={field.validationRules?.checkboxText}
        onChange={(e) => handleCheckboxTextChange(e.target.value)}
        disabled={disabled}
        error={
          activateErrors &&
          (field.validationRules?.checkboxText === undefined ||
            field.validationRules?.checkboxText === '')
        }
      />
    </div>
  );
};
