import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { Section } from '@dynamic-labs/northstar';

import { useCustomFieldsContext } from '../../../../../../app/context/CustomFieldsContext';
import { useSettingsContext } from '../../../../../../app/context/SettingsContext';
import { useEnvironmentsContext } from '../../../../../../app/context/EnvironmentsContext';

import styles from './UserInformationSection.module.scss';
import { KycFieldToggle } from './KycFieldToggle';
import { CreateNewFields } from './CreateNewFields';
import { CustomFieldsToggle } from './CustomFieldsToggle';

const kycFieldsToSkip = ['email', 'phoneNumber', 'social'];

type UserInformationSectionProps = {
  disabled: boolean;
};

export const UserInformationSection: FC<UserInformationSectionProps> = ({
  disabled,
}) => {
  const { t } = useTranslation();

  const { settings } = useSettingsContext();
  const { activeEnvironmentType } = useEnvironmentsContext();
  const { customFields } = useCustomFieldsContext();
  const { enableCustomFields } = useFlags();

  const availableKycSettings = settings[activeEnvironmentType].kyc
    .filter((field) => !kycFieldsToSkip.includes(field.name))
    .sort((a, b) => a.name.localeCompare(b.name));

  return (
    <Section
      title={t('v2.page.log_in_methods.kyc.title')}
      description={t('v2.page.log_in_methods.kyc.description')}
    >
      <div className={styles.section}>
        {availableKycSettings.map((kycField) => (
          <KycFieldToggle
            key={kycField.name}
            field={kycField}
            disabled={disabled}
          />
        ))}
        {enableCustomFields &&
          customFields
            ?.slice()
            .map((customField) => (
              <CustomFieldsToggle
                key={customField.id}
                field={customField}
                disabled={disabled}
              />
            ))}
      </div>
      {enableCustomFields && (
        <div className={styles.button}>
          <CreateNewFields />
        </div>
      )}
    </Section>
  );
};
