import React, { ReactNode, useRef } from 'react';

import { createPortal } from 'react-dom';
import { m } from 'framer-motion';
import classNames from 'classnames';

import { createModalRoot } from '../../utils/createModalRoot';

import styles from './portal.module.css';

interface Props {
  backdropClassName?: string;
  children: ReactNode;
  className?: string;
  handleClose?: () => void;
}

type PortalProps = Props & { rootId?: string };

const ModalComponent = ({
  children,
  handleClose,
  className,
  backdropClassName,
}: Props) => {
  const handleKeyClose = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Escape' && handleClose) handleClose();
  };

  const backdropClassNames = classNames(
    styles.backdrop,
    {
      [styles.backdrop__clickable]: !!handleClose,
    },
    backdropClassName,
  );

  return (
    <>
      <m.div
        initial={{ opacity: 0 }}
        animate={{
          opacity: 1,
          transition: {
            duration: 0.3,
          },
        }}
        exit={{ opacity: 0 }}
        role='button'
        onClick={handleClose}
        onKeyDown={(event) => handleKeyClose(event)}
        className={backdropClassNames}
        tabIndex={0}
        aria-label='Close modal'
      />
      <m.div
        initial={{ opacity: 0, x: '100%' }}
        animate={{
          opacity: 1,
          transition: {
            duration: 0.3,
          },
          x: 0,
        }}
        exit={{ opacity: 0, x: '100%' }}
        className={`${styles.container} ${className}`}
      >
        {children}
      </m.div>
    </>
  );
};

const Portal = ({
  children,
  handleClose,
  className,
  rootId,
  backdropClassName,
}: PortalProps) => {
  const { current } = useRef(createModalRoot(rootId));

  return createPortal(
    <ModalComponent
      handleClose={handleClose}
      className={className}
      backdropClassName={backdropClassName}
    >
      {children}
    </ModalComponent>,
    current,
  );
};

ModalComponent.defaultProps = {
  className: '',
};

export default Portal;
