import { RumEvent } from '@datadog/browser-rum';

import { logger } from '../app/services/logger';

export const rumBeforeSend = (event: RumEvent) => {
  try {
    // redact dynamic api tokens, which usually start with 'dyn_'
    const name = (event.action as any)?.target?.name;
    if (name && name.includes('dyn_')) {
      (event.action as any).target.name = 'REDACTED';
    }
  } catch (error) {
    logger.error('BeforeSend datadog error', { error });
  }
};
