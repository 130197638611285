import { useState } from 'react';

import {
  Provider,
  ProviderEnum,
  UnprocessableEntityErrorCode,
} from '@dynamic-labs/sdk-api';
import {
  useCreateProvider,
  useEnableProvider,
  useUpdateProvider,
  useDisableProvider,
} from '@dynamic-labs/redcoast-query';

import { useEnvironmentId } from '../../../app/routes/Configurations/Providers/hooks';
import { createProviderUpdateRequest } from '../../../app/routes/Configurations/Providers/utils/createProviderUpdateRequest';
import { useProvidersContext } from '../../../app/context/ProvidersContext';
import { logger } from '../../../app/services/logger';

type SaveProvidersProps = {
  providersToSave: Provider[] | Provider;
};

export type ProviderError = {
  code?: UnprocessableEntityErrorCode;
  message?: string;
  provider?: ProviderEnum;
  providerId?: string;
};

type UseSaveProviderSettingsReturn = {
  clearErrors: VoidFunction;
  errors: ProviderError[];
  isError: boolean;
  isLoading: boolean;
  saveProviders: (props: SaveProvidersProps) => Promise<void>;
};

type UseSaveProviderSettingsHook = () => UseSaveProviderSettingsReturn;

export const useSaveProvidersSettings: UseSaveProviderSettingsHook = () => {
  const {
    savedProviders,
    refetch: refetchEnvironmentProviders,
    isRefetching,
  } = useProvidersContext();

  const environmentId = useEnvironmentId();

  const [errors, setErrors] = useState<ProviderError[]>([]);

  const { mutateAsync: createProvider, isLoading: createProviderIsLoading } =
    useCreateProvider({
      onError: async (e, variables) => {
        const errorJson = await e.json();

        if ('error' in errorJson) {
          setErrors([
            ...errors,
            {
              code: 'code' in errorJson ? errorJson.code : undefined,
              message: errorJson.error,
              provider: variables.providerCreateRequest.provider,
            },
          ]);
        }
      },
    });
  const { mutateAsync: updateProvider, isLoading: updateProviderIsLoading } =
    useUpdateProvider({
      onError: async (e, variables) => {
        const errorJson = await e.json();

        if ('error' in errorJson) {
          setErrors([
            ...errors,
            {
              code: 'code' in errorJson ? errorJson.code : undefined,
              message: errorJson.error,
              providerId: variables.providerId,
            },
          ]);
        }
      },
    });
  const { mutateAsync: enableProvider, isLoading: enableProviderIsLoading } =
    useEnableProvider({
      onError: async (e, variables) => {
        const errorJson = await e.json();

        if ('error' in errorJson) {
          setErrors([
            ...errors,
            {
              code: 'code' in errorJson ? errorJson.code : undefined,
              message: errorJson.error,
              providerId: variables.providerId,
            },
          ]);
        }
      },
    });
  const { mutateAsync: disableProvider, isLoading: disableProviderIsLoading } =
    useDisableProvider({
      onError: async (e, variables) => {
        const errorJson = await e.json();

        if ('error' in errorJson) {
          setErrors([
            ...errors,
            {
              code: 'code' in errorJson ? errorJson.code : undefined,
              message: errorJson.error,
              providerId: variables.providerId,
            },
          ]);
        }
      },
    });

  const handleSaveProviders = async ({
    providersToSave,
  }: SaveProvidersProps) => {
    if (!environmentId) {
      throw new Error('Environment ID is missing');
    }

    await Promise.all(
      (providersToSave instanceof Array
        ? providersToSave
        : [providersToSave]
      ).map(async (provider) => {
        try {
          // Provider is missing id = we need to create it and enable if needed
          if (!provider.id) {
            const createdProvider = await createProvider({
              environmentId,
              providerCreateRequest: {
                accountSid: provider.accountSid,
                appleKeyId: provider.appleKeyId,
                appleTeamId: provider.appleTeamId,
                clientId: provider.clientId,
                clientSecret: provider.clientSecret,
                defaultChain: provider.defaultChain,
                enabledCountries: provider.enabledCountries,
                keyExportUrl: provider.keyExportUrl,
                provider: provider.provider,
                providerProjectId: provider.providerProjectId,
                twilioNumber: provider.twilioNumber,
              },
            });

            if (!!provider.enabledAt && createdProvider.id) {
              await enableProvider({
                providerId: createdProvider.id,
              });
            }

            return;
          }

          // Update provider and enable/disable
          await updateProvider({
            providerId: provider.id,
            providerUpdateRequest: createProviderUpdateRequest(
              savedProviders[provider.provider],
              provider,
            ),
          });

          const isProviderEnabledInApi =
            !!savedProviders[provider.provider].enabledAt;

          const isProviderEnabled = !!provider.enabledAt;

          // enable/disable only if needed to remove useless calls
          if (isProviderEnabled && !isProviderEnabledInApi) {
            await enableProvider({
              providerId: provider.id,
            });
          }

          if (!isProviderEnabled && isProviderEnabledInApi) {
            await disableProvider({
              providerId: provider.id,
            });
          }
        } catch (e) {
          logger.error(e);
        }
      }),
    );

    refetchEnvironmentProviders();
  };

  const clearErrors = () => {
    setErrors([]);
  };

  return {
    clearErrors,
    errors,
    isError: errors.length > 0,
    isLoading:
      enableProviderIsLoading ||
      disableProviderIsLoading ||
      createProviderIsLoading ||
      updateProviderIsLoading ||
      isRefetching,
    saveProviders: handleSaveProviders,
  };
};
