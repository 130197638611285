import { t } from 'i18next';
import { Trans } from 'react-i18next';

import {
  EthereumIcon,
  SolanaIcon,
  FlowIcon,
  AlgorandIcon,
  StarknetIcon,
  CosmosIcon,
  BitcoinIcon,
} from '@dynamic-labs/iconic';
import { ChainEnum, ProviderEnum } from '@dynamic-labs/sdk-api';

import {
  ChainalysisIcon,
  CustomerInformationIcon,
  GatingIcon,
  WalletConnectIcon,
  HCaptchaIcon,
  AdvancedWalletOptionsIcon,
} from '../../icons';
import { Integration } from '../types';

import { FullScope } from './subscription';

type ChainIntegration = Omit<Integration, 'category'> & {
  comingSoon?: boolean;
  id: number;
  /** A message that appears below available networks */
  networkMessage?: string;
  requireCustomRpcUrl?: boolean;
  scope?: string;
};

type WalletIntegration = Pick<
  Integration,
  'name' | 'content' | 'Icon' | 'title'
> & {
  id: number;
};

type InformationCapture = WalletIntegration & {
  scope: string;
};

type HcaptchaIntegration = WalletIntegration;

export const chainsData: ChainIntegration[] = [
  {
    Icon: EthereumIcon,
    chain: ChainEnum.Evm,
    content: {
      full: t('integrations.chains.evm.content.full'),
      short: t('integrations.chains.evm.content.short'),
    },
    id: 1,
    name: 'evm',
    title: t('integrations.chains.evm.name'),
    url: 'link-to-docs',
    walletDescription: t('integrations.chains.evm.wallet_description'),
  },
  {
    Icon: SolanaIcon,
    chain: ChainEnum.Sol,
    content: {
      full: t('integrations.chains.solana.content'),
      short: t('integrations.chains.solana.content'),
    },
    id: 2,
    name: 'solana',
    networkMessage: t('integrations.chains.solana.network_message'),
    requireCustomRpcUrl: true,
    title: t('integrations.chains.solana.name'),
    url: 'link-to-docs',
    walletDescription: t('integrations.chains.solana.wallet_description'),
  },
  {
    Icon: FlowIcon,
    chain: ChainEnum.Flow,
    content: {
      full: t('integrations.chains.flow.content'),
      short: t('integrations.chains.flow.content'),
    },
    id: 3,
    name: 'flow',
    title: t('integrations.chains.flow.name'),
    url: 'link-to-docs',
  },
  {
    Icon: BitcoinIcon,
    chain: ChainEnum.Btc,
    content: {
      full: t('integrations.chains.bitcoin.content'),
      short: t('integrations.chains.bitcoin.content'),
    },
    id: 4,
    name: 'bitcoin',
    title: t('integrations.chains.bitcoin.name'),
    url: 'link-to-docs',
  },
  {
    Icon: AlgorandIcon,
    chain: ChainEnum.Algo,
    content: {
      full: t('integrations.chains.algorand.content'),
      short: t('integrations.chains.algorand.content'),
    },
    id: 5,
    name: 'algorand',
    title: t('integrations.chains.algorand.name'),
    url: 'link-to-docs',
  },
  {
    Icon: StarknetIcon,
    chain: ChainEnum.Stark,
    content: {
      full: t('integrations.chains.starknet.content'),
      short: t('integrations.chains.starknet.content'),
    },
    id: 6,
    name: 'starknet',
    title: t('integrations.chains.starknet.name'),
    url: 'link-to-docs',
  },
  {
    Icon: CosmosIcon,
    chain: ChainEnum.Cosmos,
    content: {
      full: t('integrations.chains.cosmos.content.full'),
      short: t('integrations.chains.cosmos.content.short'),
    },
    id: 7,
    name: 'cosmos',
    title: t('integrations.chains.cosmos.name'),
    url: 'link-to-docs',
    walletDescription: t('integrations.chains.cosmos.wallet_description'),
  },
];

export const walletConnectData: WalletIntegration = {
  Icon: WalletConnectIcon,
  content: {
    full: t('integrations.wallets.walletconnect.content'),
    short: t('integrations.wallets.walletconnect.content'),
  },
  id: 1,
  name: 'wallets_walletconnect',
  title: t('integrations.wallets.walletconnect.name'),
};

export const advancedWalletOptionsData: ChainIntegration = {
  Icon: AdvancedWalletOptionsIcon,
  content: {
    full: t('integrations.wallets.advanced_options.content'),
    short: t('integrations.wallets.advanced_options.content'),
  },
  id: 1,
  name: 'advanced_wallet_options',
  scope: FullScope.MultiWallet,
  title: t('integrations.wallets.advanced_options.name'),
  url: 'link-to-docs',
};

export const onrampData: WalletIntegration = {
  Icon: AdvancedWalletOptionsIcon,
  content: {
    full: t('integrations.wallets.onramp.content'),
    short: t('integrations.wallets.onramp.content'),
  },
  id: 4,
  name: 'onramp',
  title: t('integrations.wallets.onramp.name'),
};

export const informationCaptureData: InformationCapture = {
  Icon: CustomerInformationIcon,
  content: {
    full: t('integrations.onboarding_and_kyc.customer.content'),
    short: t('integrations.onboarding_and_kyc.customer.content'),
  },
  id: 1,
  name: 'user_kyc',
  scope: FullScope.InfoCapture,
  title: t('integrations.onboarding_and_kyc.customer.name'),
};

export const onboardingAndKYCData: ChainIntegration[] = [
  {
    Icon: CustomerInformationIcon,
    comingSoon: true,
    content: {
      full: t('integrations.onboarding_and_kyc.customer.content'),
      short: t('integrations.onboarding_and_kyc.customer.content'),
    },
    id: 1,
    name: 'user_kyc',
    scope: FullScope.InfoCapture,
    title: t('integrations.onboarding_and_kyc.customer.name'),
    url: 'link-to-docs',
  },
];

export const hcaptchaData: HcaptchaIntegration = {
  Icon: HCaptchaIcon,
  content: {
    full: t('integrations.onboarding_and_kyc.hcaptcha.content'),
    short: t('integrations.onboarding_and_kyc.hcaptcha.content'),
  },
  id: 2,
  name: 'hcaptcha',
  title: t('integrations.onboarding_and_kyc.hcaptcha.name'),
};

export const accessControlData: ChainIntegration = {
  Icon: GatingIcon,
  content: {
    full: t('integrations.onboarding_and_kyc.access_control.content'),
    short: t('integrations.onboarding_and_kyc.access_control.content'),
  },
  id: 3,
  name: 'accessControl',
  scope: FullScope.AccessList,
  title: t('integrations.onboarding_and_kyc.access_control.name'),
  url: 'link-to-docs',
};

export const complianceData: ChainIntegration[] = [
  {
    Icon: ChainalysisIcon,
    content: {
      full: t('integrations.compliance.chainalysis.content'),
      short: t('integrations.compliance.chainalysis.content'),
    },
    id: 1,
    name: 'Chainalysis',
    scope: FullScope.Chainalysis,
    title: t('integrations.compliance.chainalysis.name'),
    url: 'link-to-docs',
  },
];

const CHAINALYSIS_URL =
  'https://www.chainalysis.com/free-cryptocurrency-sanctions-screening-tools/';

export const ChainalysisCopyText = ({
  urlClassName,
}: {
  urlClassName: string;
}) => (
  <Trans i18nKey='integrations.compliance.chainalysis.copy'>
    Dynamic uses the Chainalysis Sanctions API, which is a RESTful API that
    returns information for all crypto addresses on the SDN list. You can read
    the full details of how the API works and what addresses it blocks
    <a className={urlClassName} href={CHAINALYSIS_URL}>
      here
    </a>
    .
  </Trans>
);

export const getSocialProviderDocsUrl = (provider: ProviderEnum) =>
  `https://docs.dynamic.xyz/social-providers/${provider.toLowerCase()}`;
