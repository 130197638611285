import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { Section, AlertProps } from '@dynamic-labs/northstar';

import { SecurityMethodToggle } from './SecurityMethodToggle';
import styles from './MFARow.module.scss';

type MFARowProps = {
  disabled: boolean;
};

export const MFARow: FC<MFARowProps> = ({ disabled }) => {
  const { t } = useTranslation();

  const dynamicV2Alert: AlertProps = {
    action: {
      onClick: () => {
        window.open(
          'https://dynamic.xyz/slack',
          '_blank',
          'noopener,noreferrer',
        );
      },
      text: t('v2.page.embedded_wallets.dynamic_section.mfa.dynamicV2.cta'),
    },
    description: t(
      'v2.page.embedded_wallets.dynamic_section.mfa.dynamicV2.description',
    ),
    title: t('v2.page.embedded_wallets.dynamic_section.mfa.dynamicV2.title'),
    variant: 'warning',
  };

  return (
    <Section
      title={t('v2.page.embedded_wallets.dynamic_section.mfa.title')}
      description={t(
        'v2.page.embedded_wallets.dynamic_section.mfa.description',
      )}
      alerts={[dynamicV2Alert]}
      alertsAlignment='top'
    >
      <div className={styles.wrapper}>
        <SecurityMethodToggle method='passkey' disabled={disabled} />
        <SecurityMethodToggle method='email' disabled={disabled} />
      </div>
    </Section>
  );
};
