/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Session
 */
export interface Session {
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof Session
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    ipAddress?: string;
    /**
     * 
     * @type {Date}
     * @memberof Session
     */
    revokedAt?: Date | null;
}

export function SessionFromJSON(json: any): Session {
    return SessionFromJSONTyped(json, false);
}

export function SessionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Session {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'ipAddress': !exists(json, 'ipAddress') ? undefined : json['ipAddress'],
        'revokedAt': !exists(json, 'revokedAt') ? undefined : (json['revokedAt'] === null ? null : new Date(json['revokedAt'])),
    };
}

export function SessionToJSON(value?: Session | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'ipAddress': value.ipAddress,
        'revokedAt': value.revokedAt === undefined ? undefined : (value.revokedAt === null ? null : value.revokedAt.toISOString()),
    };
}

