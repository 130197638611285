import { Dispatch, FormEvent, SetStateAction } from 'react';

import { t } from 'i18next';

import { logger } from '../../../../../services/logger';
import { useEnvironmentsContext } from '../../../../../context/EnvironmentsContext';
import { addToken } from '../../helpers/addToken';
import { tokensApi } from '../../../../../services/api';
import Button from '../../../../../components/Button';
import Input from '../../../../../components/Input';
import { useTokensContext } from '../../../../../context/TokensContext';

import styles from './GenerateTokenForm.module.css';

type Props = {
  setShowModal: Dispatch<SetStateAction<boolean>>;
  setTokenName: Dispatch<SetStateAction<string>>;
  setTokenValue: Dispatch<SetStateAction<string>>;
  tokenName: string;
};

export const GenerateTokenForm = ({
  setShowModal,
  tokenName,
  setTokenName,
  setTokenValue,
}: Props) => {
  const { activeEnvironment } = useEnvironmentsContext();
  const { setTokens } = useTokensContext();

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!activeEnvironment) return;

    try {
      const { token } = await tokensApi.postToken({
        environmentId: activeEnvironment.id,
        postTokenFields: { note: tokenName },
      });

      setTokens(addToken(token));
      setTokenValue(token.rawToken);
    } catch (error) {
      logger.error(error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Input
        onChange={(e) => setTokenName(e.target.value)}
        label={t<string>('apis.create_token_modal.input_placeholder')}
        type='text'
      />
      <div className={styles.buttons}>
        <Button
          onClick={() => setShowModal(false)}
          variant='secondary'
          className={styles['button--cancel']}
          large
        >
          {t<string>('apis.create_token_modal.cancel_button')}
        </Button>
        <Button type='submit' large disabled={!tokenName}>
          {t<string>('apis.create_token_modal.add_button')}
        </Button>
      </div>
    </form>
  );
};
