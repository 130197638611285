import classNames from 'classnames';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { coldarkDark } from 'react-syntax-highlighter/dist/cjs/styles/prism';

import 'highlight.js/styles/gradient-dark.css';

import CopyButton from '../CopyButton';
import { Typography } from '../Typography';

import styles from './SingleKey.module.css';

type Props = {
  className?: string;
  ddHide?: boolean;
  extended?: boolean;
  highlightCodeSnippet?: boolean;
  keyValue: string; // ddHide is a boolean prop that is used to hide the key value for datadog rum
  title?: string;
};

export const SingleKey = ({
  className = '',
  highlightCodeSnippet,
  extended,
  keyValue,
  ddHide,
  title,
}: Props) => {
  const containerClassNames = classNames(styles.container, className, {
    [styles['container--extended']]: extended,
  });

  return (
    <div className={containerClassNames}>
      <div className={styles.key__container}>
        {highlightCodeSnippet ? (
          <SyntaxHighlighter
            language='typescript'
            style={coldarkDark}
            customStyle={{
              backgroundColor: 'transparent',
              fontSize: '0.875rem',
              lineHeight: '1.25rem',
              padding: '0',
            }}
          >
            {keyValue}
          </SyntaxHighlighter>
        ) : (
          <>
            {title && (
              <Typography variant='code-1' className={styles.key}>
                {title}
              </Typography>
            )}

            <Typography variant='code-1' className={styles.key} ddHide={ddHide}>
              {keyValue}
            </Typography>
          </>
        )}
      </div>
      <CopyButton
        textToCopy={keyValue}
        type='icon'
        className='pl-2 self-start'
      />
    </div>
  );
};
