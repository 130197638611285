import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { SocialSignInProviderEnum } from '@dynamic-labs/sdk-api';
import { SingleToggleCard } from '@dynamic-labs/northstar';

import { useSignInWithSocialProviders } from '../../../../../../../../../app/routes/Configurations/Providers/hooks/useSignInWithSocialProviders';
import { getProviderDisplayName } from '../../../../../../../../../app/utils/getProviderDisplayName';

type SocialUseForSignUpToggleProps = {
  provider: SocialSignInProviderEnum;
};

export const SocialUseForSignUpToggle: FC<SocialUseForSignUpToggleProps> = ({
  provider,
}) => {
  const { t } = useTranslation();

  const {
    listOfProviders,
    enabledProviders,
    initialProviders,
    toggleSocialSignInProvider,
  } = useSignInWithSocialProviders({
    disabled: false,
  });

  const isProviderEnabled = enabledProviders.includes(provider);

  const isProviderSaved =
    (initialProviders.includes(provider) && isProviderEnabled) ||
    (!listOfProviders.includes(provider) && !isProviderEnabled);

  return (
    <SingleToggleCard
      accordionKey={`signup-${provider}`}
      title={t(
        'v2.page.log_in_methods.social.drawer.authentication.signup.title',
      )}
      description={t(
        'v2.page.log_in_methods.social.drawer.authentication.signup.description',
        {
          provider: getProviderDisplayName(provider),
        },
      )}
      allowExpand={false}
      inputProps={{
        checked: isProviderEnabled,
        id: `signup-${provider}`,
        isSaved: isProviderSaved,
        onChange: () => toggleSocialSignInProvider(provider),
        type: 'toggle',
      }}
    />
  );
};
