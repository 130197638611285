/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-multi-assign */
/* eslint-disable no-underscore-dangle */
import { useState, useEffect, useMemo } from 'react';

import { datadogRum } from '@datadog/browser-rum';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { useDynamicContext } from '@dynamic-labs/sdk-react-core';

import environment from '../../../environments/environment';
import { useEnvironmentsContext } from '../../context/EnvironmentsContext';
import { useDashboardContext } from '../../context/DashboardContext';
import { EmptyPaper } from '../../components/EmptyPaper';
import { useEnvironmentVisitorsQuery } from '../../hooks/useEnvironmentVisitorsQuery';
import Portal from '../../components/Portal';
import CreateFirstOrganization from '../OnBoarding/CreateOrJoinOrganization/CreateFirstOrganization';

import styles from './overview.module.css';
import { QuickStartStepper } from './components/QuickStartStepper';
import { OnboardingDoneContent } from './components/OnboardingDoneContent';
import { Sidebar } from './components/Sidebar';

const SANDBOX_MAX_STEPS_COUNT = 3;

declare global {
  interface Window {
    _hsq?: any[];
  }
}

export const OverviewRoute = () => {
  const { user, primaryWallet } = useDynamicContext();

  const { userOrganizations, setUserProjects } = useDashboardContext();
  const { environments } = useEnvironmentsContext();
  const { isLoading: isLoadingLiveVisitors, data: liveVisitors } =
    useEnvironmentVisitorsQuery({
      environmentId: environments?.live.id,
    });

  const { enableHubspotIdentityTracking } = useFlags();

  const { isLoading: isLoadingSandboxVisitors, data: sandboxVisitors } =
    useEnvironmentVisitorsQuery({
      environmentId: environments?.sandbox.id,
    });

  const isLoading = useMemo(
    () => isLoadingLiveVisitors || isLoadingSandboxVisitors,
    [isLoadingLiveVisitors, isLoadingSandboxVisitors],
  );

  const [currentSandboxStep, setCurrentSandboxStep] = useState(0);

  useEffect(() => {
    if (user && enableHubspotIdentityTracking) {
      const _hsq = (window._hsq = window._hsq || []);
      _hsq.push(['identify', { email: user.email, id: user.userId }]);
      _hsq.push(['trackPageView']);
    }
  }, [user, enableHubspotIdentityTracking]);

  useEffect(() => {
    if (user && primaryWallet && environment.datadogRum) {
      datadogRum.setUser({
        email: user.email,
        firstName: user.firstName,
        id: user.userId,
        organizationIds: userOrganizations.map((org) => org.id),
        organizationNames: userOrganizations.map((org) => org.name),
        organizations: userOrganizations.map((org) => ({
          id: org.id,
          name: org.name,
        })),
        walletName: primaryWallet.key,
        walletPublicKey: primaryWallet.address,
      });
    }
  }, [
    user?.userId,
    userOrganizations,
    primaryWallet?.address,
    user,
    primaryWallet,
  ]);

  const isAnyCounterGreaterThanZero = useMemo(
    () =>
      sandboxVisitors &&
      liveVisitors &&
      (sandboxVisitors.users.count > 0 ||
        sandboxVisitors.visitors.count > 0 ||
        liveVisitors.users.count > 0 ||
        liveVisitors.visitors.count > 0),
    [liveVisitors, sandboxVisitors],
  );

  const contentToRender = useMemo(() => {
    if (isAnyCounterGreaterThanZero) {
      setCurrentSandboxStep(SANDBOX_MAX_STEPS_COUNT);
      return <OnboardingDoneContent />;
    }
    return (
      <QuickStartStepper
        onStepChange={(activeStep) => setCurrentSandboxStep(activeStep)}
      />
    );
  }, [isAnyCounterGreaterThanZero]);

  return (
    <>
      <div className={styles.overview}>
        <section className={styles.wrapper} data-testid='overview'>
          <div className={styles.container}>
            {isLoading || !liveVisitors || !sandboxVisitors
              ? [...Array(SANDBOX_MAX_STEPS_COUNT).keys()].map((k) => (
                  <EmptyPaper key={`empty-${k}`} />
                ))
              : contentToRender}
          </div>
        </section>
        <Sidebar
          currentSandboxStep={currentSandboxStep}
          sandboxMaxStepsCount={SANDBOX_MAX_STEPS_COUNT}
          isLoading={isLoading}
        />
      </div>

      {!userOrganizations.length && (
        <Portal className={styles.portal} backdropClassName={styles.backdrop}>
          <CreateFirstOrganization
            setUserProjects={setUserProjects}
            disabled={false}
            user={user}
          />
        </Portal>
      )}
    </>
  );
};
