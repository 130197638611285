import { useCallback, useEffect, useMemo, useState } from 'react';

import { Provider, ProviderEnum } from '@dynamic-labs/sdk-api';

import {
  convertProvidersToMap,
  transformProvidersMapToCompare,
} from '../../utils';
import { useSignInWithSocialProviders } from '../useSignInWithSocialProviders';

import { verifyProviderRules } from './helpers';

type SetProviderValueCallback = <FIELDS extends keyof Provider>(
  provider: ProviderEnum,
  field: FIELDS,
  value: Provider[FIELDS],
) => void;

type GetProviderValueCallback = <FIELDS extends keyof Provider>(
  provider: ProviderEnum,
  field: FIELDS,
) => Provider[FIELDS];

export type FIELDS = keyof Provider;

export type UseManipulateProviderStateReturnValue = {
  currentProviders: Record<ProviderEnum, Provider>;
  getProviderValue: GetProviderValueCallback;
  hasChanges: boolean;
  hasProviderChanged: (provider: ProviderEnum) => boolean;
  isProviderEnabled: (provider: ProviderEnum) => boolean;
  onChangeProvider: (
    provider: ProviderEnum,
    field: FIELDS,
    value: Provider[FIELDS],
  ) => void;
  providers: Provider[];
  resetProviderState: VoidFunction;
  setProviderValue: SetProviderValueCallback;
  toggleProvider: (provider: ProviderEnum, value: boolean) => false | void;
};

// ## useManipulateProviderState
// Please do not use this hook directly.
// It is used by the ProvidersContext to manipulate on the states of the providers.
// Check out **useProvidersContext** instead.
//
export const useManipulateProviderState = (
  initialValue: Record<ProviderEnum, Provider>,
  disabled: boolean,
): UseManipulateProviderStateReturnValue => {
  const { disableAllSocialSignInProviders } = useSignInWithSocialProviders({
    disabled,
  });

  const [providers, setProviders] =
    useState<Record<ProviderEnum, Provider>>(initialValue);

  useEffect(() => {
    setProviders(initialValue);
  }, [initialValue, setProviders]);

  const createDefaultProvider = (provider: ProviderEnum): Provider => ({
    defaultChain: undefined,
    provider,
  });

  const setProviderValue: SetProviderValueCallback = useCallback(
    (provider, field, value) => {
      setProviders((prevProviders) => {
        const isProviderPresent = !!prevProviders[provider];

        // copy old providers
        let newProviders = {
          ...prevProviders,
        };

        // If there is no provider in the database we need to create a new one
        if (!isProviderPresent) {
          newProviders = {
            ...newProviders,
            [provider]: createDefaultProvider(provider),
          };
        }

        newProviders = verifyProviderRules({
          disableAllSocialSignInProviders,
          field,
          newProviders,
          prevProviders,
          provider,
          value,
        });

        return {
          ...newProviders,
          [provider]: {
            ...newProviders[provider],
            [field]: value,
          },
        };
      });
    },
    [disableAllSocialSignInProviders],
  );

  const getProviderValue: GetProviderValueCallback = useCallback(
    (provider, field) => {
      if (!providers[provider]) {
        return createDefaultProvider(provider)[field];
      }

      return providers[provider][field];
    },
    [providers],
  );

  const resetProviderState = useCallback(() => {
    setProviders(initialValue);
  }, [setProviders, initialValue]);

  const isProviderEnabled = useCallback(
    (provider: ProviderEnum) => !!getProviderValue(provider, 'enabledAt'),
    [getProviderValue],
  );

  const toggleProvider = useCallback(
    (provider: ProviderEnum, value: boolean) =>
      !disabled &&
      setProviderValue(provider, 'enabledAt', value ? new Date() : undefined),
    [disabled, setProviderValue],
  );

  const onChangeProvider = useCallback(
    (provider: ProviderEnum, field: FIELDS, value: Provider[FIELDS]) =>
      !disabled && setProviderValue(provider, field, value ?? undefined),
    [disabled, setProviderValue],
  );

  const hasProviderChanged = useCallback(
    (provider: ProviderEnum) => {
      if (
        initialValue[provider] === undefined &&
        providers[provider] &&
        providers[provider].enabledAt === undefined &&
        providers[provider].defaultChain === undefined &&
        providers[provider].clientId === undefined &&
        providers[provider].clientSecret === undefined &&
        providers[provider].appleTeamId === undefined &&
        providers[provider].appleKeyId === undefined
      ) {
        return false;
      }

      const providerAsRecord = convertProvidersToMap(
        providers[provider] ? [providers[provider]] : [],
      );
      const initialValueAsRecord = convertProvidersToMap(
        initialValue[provider] ? [initialValue[provider]] : [],
      );

      return (
        JSON.stringify(transformProvidersMapToCompare(providerAsRecord)) !==
        JSON.stringify(transformProvidersMapToCompare(initialValueAsRecord))
      );
    },
    [initialValue, providers],
  );

  const hasChanges = useMemo(() => {
    const changedProviders = Object.values(providers).filter((provider) =>
      hasProviderChanged(provider.provider),
    );

    return changedProviders.length > 0;
  }, [providers, hasProviderChanged]);

  return {
    currentProviders: providers,
    getProviderValue,
    hasChanges,
    hasProviderChanged,
    isProviderEnabled,
    onChangeProvider,
    providers: Object.values(providers),
    resetProviderState,
    setProviderValue,
    toggleProvider,
  };
};
