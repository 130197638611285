import { t } from 'i18next';

import { useDashboardContext } from '../../../../context/DashboardContext';
import CreateProjectForm from '../../../../routes/Dashboard/Forms/CreateProjectForm';
import Portal from '../../../Portal';
import Header from '../../../Portal/Header';

import styles from './add-project-modal.module.css';

interface Props {
  organizationId: string;
  setShowModal: (showModal: boolean) => void;
}

const AddProjectModal = ({ setShowModal, organizationId }: Props) => {
  const { setActiveProjectId, userProjects } = useDashboardContext();

  return (
    <Portal handleClose={() => setShowModal(false)} className={styles.portal}>
      <Header
        handleClose={() => setShowModal(false)}
        headingText={t('create_project.title')}
      />
      <CreateProjectForm
        submitText={t('create_project.add')}
        cancelText={t('create_project.cancel')}
        cancelAction={() => setShowModal(false)}
        submitAction={() => setShowModal(false)}
        organizationId={organizationId}
        setActiveProjectId={setActiveProjectId}
        userProjects={userProjects}
      />
    </Portal>
  );
};

export default AddProjectModal;
