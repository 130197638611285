import { t } from 'i18next';

import { useDashboardContext } from '../../../../context/DashboardContext';
import CreateOrganizationForm from '../../../../routes/OnBoarding/Forms/CreateOrganizationForm';
import Portal from '../../../Portal';
import Header from '../../../Portal/Header';

import styles from './add-organization-modal.module.css';

interface Props {
  setShowModal: (showModal: boolean) => void;
}

const AddOrganizationModal = ({ setShowModal }: Props) => {
  const { setUserProjects, userOrganizations, userProjects } =
    useDashboardContext();

  return (
    <Portal handleClose={() => setShowModal(false)} className={styles.portal}>
      <Header
        handleClose={() => setShowModal(false)}
        headingText={t('create_org.title')}
      />
      <CreateOrganizationForm
        setUserProjects={setUserProjects}
        userOrganizations={userOrganizations}
        userProjects={userProjects}
        submitText={t('create_org.add')}
        cancelText={t('create_org.cancel')}
        cancelAction={() => setShowModal(false)}
        submitAction={() => setShowModal(false)}
      />
    </Portal>
  );
};

export default AddOrganizationModal;
