import { useMemo } from 'react';

import { format, parse } from 'date-fns';
import { t } from 'i18next';

import { useEnvironmentsContext } from '../../../../context/EnvironmentsContext';
import {
  ClosableInfoAlert,
  ClosableInfoAlertIds,
} from '../../../../components/ClosableInfoAlert';
import { BarChart, BarData } from '../../../../components/Chart';
import { useVisitAnalyticsQuery } from '../../hooks';
import { fillMissingDays } from '../../utils';
import { WidgetView } from '../WidgetView';
import { LoadingBarChart } from '../LoadingBarChart';
import { ChartSummaryItem } from '../WidgetView/ChartSummaryItem/ChartSummaryItem';
import { Typography } from '../../../../components/Typography';
import { Alert } from '../../../../components/Alert';

import styles from './DailyUsersBarChartWidget.module.css';
import { DailyUsersBarChartWidgetComponent } from './DailyUsersBarChartWidget.types';

export const DailyUsersBarChartWidget: DailyUsersBarChartWidgetComponent = ({
  disabled,
  endDate,
  environmentId,
  startDate,
}) => {
  const { activeEnvironmentType } = useEnvironmentsContext();
  const { data: visitAnalyticsData, isLoading } = useVisitAnalyticsQuery({
    endDate,
    environmentId,
    startDate,
  });

  const connectedUsersSessions = useMemo(
    () =>
      visitAnalyticsData?.visits?.sessions?.reduce(
        (total, session) => total + (session.count || 0),
        0,
      ),
    [visitAnalyticsData],
  );

  const authenticatedUsersSessions = useMemo(
    () =>
      visitAnalyticsData?.users?.sessions?.reduce(
        (total, session) => total + (session.count || 0),
        0,
      ),
    [visitAnalyticsData],
  );

  const isEmpty = useMemo(
    () =>
      visitAnalyticsData?.users?.sessions?.length === 0 &&
      visitAnalyticsData?.visits?.sessions?.length === 0,
    [visitAnalyticsData],
  );

  const data = useMemo(() => {
    if (!startDate || !endDate || !visitAnalyticsData)
      return { users: [], visits: [] };

    const sessions = visitAnalyticsData?.users?.sessions?.map(
      ({ count = 0, date }) => ({
        count,
        date: parse(date || '', 'yyyy-MM-dd', new Date()),
      }),
    );

    const visits = visitAnalyticsData?.visits?.sessions?.map(
      ({ count = 0, date }) => ({
        count,
        date: parse(date || '', 'yyyy-MM-dd', new Date()),
      }),
    );

    if (!sessions || !visits) return { users: [], visits: [] };

    return {
      users: fillMissingDays({
        data: sessions,
        endDate,
        fillFn: (date) => ({
          count: 0,
          date,
        }),
        getter: ({ date }) => date,
        startDate,
      }),
      visits: fillMissingDays({
        data: visits,
        endDate,
        fillFn: (date) => ({
          count: 0,
          date,
        }),
        getter: ({ date }) => date,
        startDate,
      }),
    };
  }, [endDate, visitAnalyticsData, startDate]);

  const barChartData: BarData | undefined = useMemo(() => {
    if (!data) return;

    return {
      datasets: [
        {
          backgroundColor: 'rgba(25, 64, 169, 1)',
          data: data.users.map(({ count }) => count),
        },
        {
          backgroundColor: 'rgba(92, 137, 255, 1)',
          data: data.visits.map(({ count }) => count),
        },
      ],
      labels: data.users.map(({ date }) => format(date, 'MM-dd')),
    };
  }, [data]);

  return (
    <WidgetView title={t<string>('analytics.widgets.daily_users.title')}>
      <div className={styles.summary__wrapper}>
        <ChartSummaryItem
          userType='visitors'
          label={t('analytics.widgets.daily_users.visitors_label')}
          value={connectedUsersSessions}
        />
        <ChartSummaryItem
          userType='unique'
          label={t('analytics.widgets.daily_users.authenticated_user_label')}
          value={authenticatedUsersSessions}
        />
        {activeEnvironmentType === 'sandbox' && (
          <Alert severity='info' className={styles['sanbox-message']}>
            <Typography variant='paragraph-1'>
              {t<string>('users_management_table.sandbox_limit_users_message')}
            </Typography>
            <a
              href='https://docs.dynamic.xyz/developer-dashboard/sandbox-vs-live'
              target='_blank'
              rel='noreferrer'
            >
              {t<string>('users_management_table.sandbox')}
            </a>
          </Alert>
        )}
      </div>

      <div className={styles['widget-children']}>
        {isLoading || disabled ? (
          <div className={styles['chart-container']}>
            <LoadingBarChart startDate={startDate} endDate={endDate} />
          </div>
        ) : (
          <div className={styles['chart-container']}>
            {isEmpty && (
              <div className={styles['empty-container']}>
                {t<string>('analytics.widgets.daily_users.empty_label')}
              </div>
            )}

            {barChartData && (
              <BarChart
                options={{
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                }}
                data={barChartData}
              />
            )}
          </div>
        )}
      </div>

      <div className='mb-4 !border-0'>
        {/* todo: add "learnMoreLink" param with upgrade */}
        <ClosableInfoAlert
          id={ClosableInfoAlertIds.analyticsUsers}
          isClosable
          title={t('analytics.closable_banner.title')}
          message={t('analytics.closable_banner.message')}
          learnMoreLink='https://docs.dynamic.xyz/branded-wallets/connected-vs-authenticated#the-difference-in-practice'
        />
      </div>
    </WidgetView>
  );
};
