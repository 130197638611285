import React from 'react';

import { t } from 'i18next';

import { RoleEnum } from '@dynamic-labs/sdk-api';

import {
  AdminIcon,
  AnalyticsIcon,
  ApiIcon,
  DocumentationIcon,
  ConfigurationsIcon,
  OverviewIcon,
  UserManagement,
  SettingsIcon,
  DesignIcon,
  ChainAndNetworksIcon,
  PluginsIcon,
} from '../../../icons';

import { DropdownItemData } from './NavigationItemDropdown/NavigationItemDropdown';

export type NavigationData = {
  [key: string]: {
    Icon: React.FunctionComponent;
    dropdown?: DropdownItemData[];
    external?: boolean;
    linkTo: string;
    minRole: RoleEnum;
    name: string;
  }[];
};

export interface IUtilData {
  badgeCopy: string;
  logOut: string;
  sectionHeadings: Record<string, string>;
  title: string;
}

export enum ROUTES {
  admin = '/dashboard/admin',
  adminSubscriptionTab = '/dashboard/admin#subscription',
  analytics = '/dashboard/analytics',
  api = '/dashboard/api',
  chainsAndNetworks = '/dashboard/chains-and-networks',
  coinbaseWaasKeyExport = '/coinbase-waas-key-export',
  configurations = '/dashboard/configurations',
  dashboard = '/dashboard',
  design = '/dashboard/design',
  designModal = '/dashboard/design/modal',
  designWidget = '/dashboard/design/widget',
  developer = '/dashboard/developer',
  developerApi = '/dashboard/developer/api',
  developerThirdPartyAuth = '/dashboard/developer/third-party-auth',
  developerWebhooks = '/dashboard/developer/webhooks',
  documentation = 'https://docs.dynamic.xyz',
  embeddedWallets = '/dashboard/embedded-wallets',
  embeddedWalletsDynamic = '/dashboard/embedded-wallets/dynamic',
  embeddedWalletsThirdParty = '/dashboard/embedded-wallets/third-party',
  home = '/',
  logInMethods = '/dashboard/log-in-user-profile',
  onboarding = '/dashboard/onboarding',
  overview = '/dashboard/overview',
  plugins = '/dashboard/plugins',
  sdkOAuth = '/sdkOAuth',
  security = '/dashboard/security',
  settings = '/dashboard/settings',
  settingsGeneral = '/dashboard/settings/general',
  settingsPrivacy = '/dashboard/settings/privacy',
  settingsSecurity = '/dashboard/settings/security',
  users = '/dashboard/users',
  usersAuthenticated = '/dashboard/users/authenticated',
  usersVisitors = '/dashboard/users/visitors',
}

export const utilData: IUtilData = {
  badgeCopy: 'Alpha',
  logOut: t('navigation.user_info.log_out'),
  sectionHeadings: {
    customizations: 'Customizations',
    more: 'More',
    workspace: 'Workspace',
  },
  title: 'Dynamic',
};

export const navigationData = (
  docsUrl: string = ROUTES.documentation,
): NavigationData => ({
  customizations: [
    {
      Icon: ApiIcon,
      dropdown: [
        {
          dropdownLinkTo: ROUTES.developerApi,
          dropdownName: 'SDK & API Keys',
          minRole: RoleEnum.Developer,
        },
        {
          dropdownLinkTo: ROUTES.developerWebhooks,
          dropdownName: 'Webhooks',
          minRole: RoleEnum.Developer,
        },
        {
          dropdownLinkTo: ROUTES.developerThirdPartyAuth,
          dropdownName: 'Third-Party Auth',
          minRole: RoleEnum.Developer,
        },
      ],
      linkTo: ROUTES.developer,
      minRole: RoleEnum.Developer,
      name: 'Developer',
    },
    {
      Icon: ConfigurationsIcon,
      linkTo: ROUTES.configurations,
      minRole: RoleEnum.Developer,
      name: 'Configurations',
    },
    {
      Icon: PluginsIcon,
      linkTo: ROUTES.plugins,
      minRole: RoleEnum.Developer,
      name: 'Plugins',
    },
    {
      Icon: ChainAndNetworksIcon,
      linkTo: ROUTES.chainsAndNetworks,
      minRole: RoleEnum.Developer,
      name: 'Chains & Networks',
    },
    {
      Icon: SettingsIcon,
      dropdown: [
        {
          dropdownLinkTo: ROUTES.settingsGeneral,
          dropdownName: 'General',
          minRole: RoleEnum.Developer,
        },
        {
          dropdownLinkTo: ROUTES.security,
          dropdownName: 'Security',
          minRole: RoleEnum.Developer,
        },
        {
          dropdownLinkTo: ROUTES.settingsPrivacy,
          dropdownName: 'Privacy',
          minRole: RoleEnum.Developer,
        },
      ],
      linkTo: ROUTES.settings,
      minRole: RoleEnum.Developer,
      name: 'Settings',
    },
    {
      Icon: DesignIcon,
      linkTo: ROUTES.design,
      minRole: RoleEnum.Developer,
      name: 'Design',
    },
  ],
  more: [
    {
      Icon: DocumentationIcon,
      external: true,
      linkTo: docsUrl,
      minRole: RoleEnum.Viewer,
      name: 'Documentation',
    },
    {
      Icon: AdminIcon,
      linkTo: ROUTES.admin,
      minRole: RoleEnum.Admin,
      name: 'Admin',
    },
  ],
  workspace: [
    {
      Icon: OverviewIcon,
      linkTo: ROUTES.overview,
      minRole: RoleEnum.Viewer,
      name: 'Overview',
    },
    {
      Icon: AnalyticsIcon,
      linkTo: ROUTES.analytics,
      minRole: RoleEnum.Viewer,
      name: 'Analytics',
    },
    {
      Icon: UserManagement,
      dropdown: [
        {
          dropdownLinkTo: ROUTES.usersAuthenticated,
          dropdownName: 'Users',
          minRole: RoleEnum.Viewer,
        },
        {
          dropdownLinkTo: ROUTES.usersVisitors,
          dropdownName: 'Visitors',
          minRole: RoleEnum.Viewer,
        },
      ],
      linkTo: ROUTES.users,
      minRole: RoleEnum.Viewer,
      name: 'User management',
    },
  ],
});
