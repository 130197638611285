import { FC, PropsWithChildren, useEffect, useRef } from 'react';

import { Content } from '@radix-ui/react-accordion';
import classNames from 'classnames';

import styles from './AccordionContent.module.scss';

type AccordionContentProps = {
  animationDurationMS?: number;
  className?: string;
};
export const AccordionContent: FC<PropsWithChildren<AccordionContentProps>> = ({
  children,
  animationDurationMS = 300,
  className,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  // Radix Accordion doesnt update height when children updates.
  // We need to manualy add a resize observer on children of the Content component
  // to update the css variable height of the content.
  useEffect(() => {
    const content = contentRef.current;

    if (!ref.current || !content) return;

    const resizeObserver = new ResizeObserver(() => {
      const currentHeight = ref.current?.clientHeight;

      content.style.cssText = `--radix-accordion-content-height: ${currentHeight}px;`;
    });

    resizeObserver.observe(ref.current);

    return () => resizeObserver.disconnect(); // clean up
  }, []);

  return (
    <Content
      className={classNames(styles.content, className)}
      style={{
        animationDuration: `${animationDurationMS}ms`,
      }}
      ref={contentRef}
      data-testid='accordion-content'
    >
      <div ref={ref}>{children}</div>
    </Content>
  );
};
