/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ChainEnum {
    Eth = 'ETH',
    Evm = 'EVM',
    Flow = 'FLOW',
    Sol = 'SOL',
    Algo = 'ALGO',
    Stark = 'STARK',
    Cosmos = 'COSMOS',
    Btc = 'BTC'
}

export function ChainEnumFromJSON(json: any): ChainEnum {
    return ChainEnumFromJSONTyped(json, false);
}

export function ChainEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChainEnum {
    return json as ChainEnum;
}

export function ChainEnumToJSON(value?: ChainEnum | null): any {
    return value as any;
}

