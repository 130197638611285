import { useCallback, useEffect, useMemo, useState } from 'react';

import { t } from 'i18next';

import { useLocalStorage } from '@dynamic-labs/sdk-react-core';

import { useSubscriptionLock } from '../../../../hooks/useSubscriptionLock';
import { useDashboardContext } from '../../../../context/DashboardContext';
import { Typography } from '../../../../components/Typography';
import { Accordion } from '../../../../components/Accordion';
import { QUICKSTART_ONBOARDING_STEP } from '../../../../utils/constants';
import { QuickStartStep } from '../../utils';
import { TrialGiftCard } from '../Card/TrialGiftCard/TrialGiftCard';

import styles from './QuickStartStepper.module.css';
import { InstallSdkStep } from './components/InstallSdkStep';
import { CopySnippetStep } from './components/CopySnippetStep';
import { DeployAndConnectWalletStep } from './components/DeployAndConnectWalletStep';

export const QuickStartStepper = ({
  onStepChange,
}: {
  onStepChange: (arg: number) => void;
}) => {
  const [lastActiveStep, setLastActiveStep] = useState(1);
  const [stepToggleClassMap, setStepToggleClassMap] = useState<
    Record<number, boolean>
  >({});
  const { activeOrganizationId } = useDashboardContext();
  const { shouldShowTrialBanner } = useSubscriptionLock();

  const [lsQuickstartStep, setLsQuickstartStep] = useLocalStorage(
    `${QUICKSTART_ONBOARDING_STEP}_${activeOrganizationId}`,
    0,
  );

  const handleItemSelected = useCallback(
    (index: number, isCompleted: boolean) => {
      if (!isCompleted) {
        return;
      }

      setStepToggleClassMap((prevState) => ({
        ...prevState,
        [index]: !prevState[index],
      }));
    },
    [setStepToggleClassMap],
  );

  const handleStepCompleted = useCallback(
    (step: number) => {
      setLastActiveStep(step);
      setLsQuickstartStep(step);
      onStepChange(step);
      handleItemSelected(step - 1, true);
    },
    [setLastActiveStep, setLsQuickstartStep, onStepChange, handleItemSelected],
  );

  const steps: QuickStartStep[] = useMemo(
    () => [
      {
        content: <InstallSdkStep onStepCompleted={handleStepCompleted} />,
        id: 1,
        key: 'install',
        subtitle: t('overview.steps.first.subtitle'),
        title: t('overview.steps.first.title'),
      },
      {
        content: <CopySnippetStep onStepCompleted={handleStepCompleted} />,
        id: 2,
        key: 'copy',
        subtitle: t('overview.steps.second.subtitle'),
        title: t('overview.steps.second.title'),
      },
      {
        content: <DeployAndConnectWalletStep />,
        id: 3,
        key: 'deploy',
        title: t('overview.steps.last.title'),
      },
    ],
    [handleStepCompleted],
  );

  useEffect(() => {
    setLastActiveStep(lsQuickstartStep);
    onStepChange(lsQuickstartStep);

    const activeStatusSteps = {};
    steps.forEach((step, index) => {
      Object.assign(activeStatusSteps, { [index]: index === lsQuickstartStep });
    });
    setStepToggleClassMap(activeStatusSteps);
  }, [lsQuickstartStep, setLastActiveStep, steps, onStepChange]);

  return (
    <>
      <div className={styles['top-area']}>
        <Typography
          as='h1'
          weight='medium'
          className={styles['top-area__heading--main']}
        >
          {t<string>('overview.heading.title')}
        </Typography>
        <Typography
          as='h2'
          variant='paragraph-2'
          className={styles['top-area__heading--sub']}
        >
          {t<string>('overview.steps.heading.subtitle')}
        </Typography>
      </div>

      {shouldShowTrialBanner && (
        <div className='mb-4'>
          <TrialGiftCard />
        </div>
      )}

      <div
        className={styles.accordion__container}
        data-testid='quickstart-stepper'
      >
        {steps.map((step, index) => {
          const stepCompleted = lastActiveStep > index;
          return (
            <Accordion
              onClick={() => handleItemSelected(index, stepCompleted)}
              isCompleted={stepCompleted}
              isActive={stepToggleClassMap[index]}
              content={step}
              key={step.key}
            />
          );
        })}
      </div>
    </>
  );
};
