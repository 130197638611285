import { useState } from 'react';

import { Field, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { object, string } from 'yup';

import { Project } from '@dynamic-labs/sdk-api';

import { logger } from '../../../../services/logger';
import Button from '../../../../components/Button';
import { projectsApi } from '../../../../services/api';
import Input from '../../../../components/Input';
import {
  useEnvironmentsContext,
  DEFAULT_ENV,
} from '../../../../context/EnvironmentsContext';
import { useDashboardContext } from '../../../../context/DashboardContext';

import { FormErrors } from './FormErrors';
import styles from './create-project-form.module.css';
import { validateProjectName } from './helpers';

interface FormValues {
  projectName: string;
}

interface Props {
  cancelAction?: () => void;
  cancelText?: string;
  organizationId: string;
  setActiveProjectId: (projectId: string | undefined) => void;
  submitAction?: () => void;
  submitText: string;
  userProjects: Project[];
}

const CreateProjectForm = ({
  organizationId,
  submitText,
  cancelText,
  submitAction,
  cancelAction,
  setActiveProjectId,
  userProjects,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { setActiveEnvironmentType } = useEnvironmentsContext();
  const { fetchUserProjects } = useDashboardContext();

  const onSubmit = async (values: FormValues) => {
    try {
      setLoading(true);

      const { project } = await projectsApi.createProject({
        organizationId,
        projectRequest: {
          name: values.projectName,
        },
      });

      if (!project?.id) return;

      fetchUserProjects();

      setActiveProjectId(project.id);
      setActiveEnvironmentType(DEFAULT_ENV);
    } catch (e) {
      logger.error(e);
    } finally {
      if (submitAction) submitAction();
      setLoading(false);
    }
  };

  const validationSchema = object().shape({
    projectName: string()
      .trim()
      .required('required')
      .matches(
        /^(?=\S)[a-zA-Z0-9 _.,:!'?&%@\\/-]+(?<=\S)$/,
        'invalidProjectNameError',
      )
      .test('duplicated_name', '', (value, context) =>
        validateProjectName(value, context, userProjects),
      ),
  });

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{ projectName: '' }}
      validationSchema={validationSchema}
    >
      {({ errors, touched }) => (
        <Form>
          <FormErrors
            errors={errors}
            showErrors={
              !!Object.keys(touched).length && !!Object.keys(errors).length
            }
          />

          <Field
            as={Input}
            name='projectName'
            id='projectName'
            label={t('create_project.projectNameLabel')}
            error={!!errors.projectName && !!touched.projectName}
          />

          <div className={styles.buttons}>
            {cancelText && (
              <Button
                type='button'
                large
                variant='secondary'
                onClick={cancelAction}
              >
                {cancelText}
              </Button>
            )}
            <Button type='submit' loading={loading} large>
              {submitText}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CreateProjectForm;
