import classNames from 'classnames';

import { SmallWidgetViewComponent } from './SmallWidgetView.types';
import styles from './SmallWidgetView.module.css';

export const SmallWidgetView: SmallWidgetViewComponent = ({
  disabled = false,
  title,
  value,
}) => {
  const containerClassNames = classNames(styles.container, {
    [styles['container--disabled']]: disabled,
  });

  return (
    <div className={containerClassNames}>
      <div className={styles.title}>{title}</div>

      <div className={styles.value}>{value}</div>
    </div>
  );
};
