import { Dispatch, SetStateAction, useState } from 'react';

import { t } from 'i18next';

import Portal from '../../../../components/Portal';
import Header from '../../../../components/Portal/Header';

import { CopyTokenView } from './CopyTokenView';
import { GenerateTokenForm } from './GenerateTokenForm';
import styles from './CreateTokenModal.module.css';

type Props = {
  setShowModal: Dispatch<SetStateAction<boolean>>;
};

type ModalContentProps = Props & {
  setTokenValue: Dispatch<SetStateAction<string>>;
  tokenValue: string;
};

export const CreateTokenModalContent = ({
  setShowModal,
  tokenValue,
  setTokenValue,
}: ModalContentProps) => {
  const [tokenName, setTokenName] = useState('');

  return (
    <>
      {!tokenValue && (
        <GenerateTokenForm
          setShowModal={setShowModal}
          tokenName={tokenName}
          setTokenName={setTokenName}
          setTokenValue={setTokenValue}
        />
      )}
      {tokenValue && (
        <CopyTokenView
          setShowModal={setShowModal}
          tokenName={tokenName}
          tokenValue={tokenValue}
        />
      )}
    </>
  );
};

export const CreateTokenModal = ({ setShowModal }: Props) => {
  const [tokenValue, setTokenValue] = useState('');
  return (
    <Portal
      handleClose={tokenValue ? () => {} : () => setShowModal(false)}
      className={styles.portal}
    >
      <Header headingText={t('apis.create_token_modal.title')} />
      <CreateTokenModalContent
        setShowModal={setShowModal}
        tokenValue={tokenValue}
        setTokenValue={setTokenValue}
      />
    </Portal>
  );
};
