import { t } from 'i18next';

import Portal from '../Portal';
import Header from '../Portal/Header';
import Button from '../Button';

import styles from './DeleteModal.module.css';

type DeleteModalProps = {
  loading?: boolean;
  onClose(): void;
  onDelete(): void;
  text: string;
  title: string;
};

export const DeleteModal = ({
  title,
  text,
  onClose,
  onDelete,
  loading = false,
}: DeleteModalProps) => (
  <Portal
    handleClose={onClose}
    className={styles.portal}
    backdropClassName='!z-[31]'
  >
    <Header
      className={styles.header}
      handleClose={onClose}
      headingText={title}
    />
    <div className={styles.content}>{text}</div>
    <div className={styles.buttons}>
      <Button
        variant='secondary'
        type='button'
        disabled={loading}
        onClick={onClose}
      >
        {t<string>('actions.cancel')}
      </Button>
      <Button
        className={styles.button__delete}
        type='button'
        variant='secondary'
        loading={loading}
        onClick={onDelete}
      >
        {t<string>('actions.delete')}
      </Button>
    </div>
  </Portal>
);
