import { Provider, ProviderUpdateRequest } from '@dynamic-labs/sdk-api';

export const createProviderUpdateRequest = (
  savedProvider: Provider,
  provider: Provider,
): ProviderUpdateRequest => {
  const didClientSecretChange =
    savedProvider.clientSecret !== provider.clientSecret;

  return {
    accountSid: provider.accountSid,
    appleKeyId: provider.appleKeyId,
    appleTeamId: provider.appleTeamId,
    clientId: provider.clientId,
    defaultChain: provider.defaultChain,
    enabledCountries: provider.enabledCountries,
    keyExportUrl: provider.keyExportUrl,
    providerProjectId: provider.providerProjectId,
    twilioNumber: provider.twilioNumber,
    ...(didClientSecretChange ? { clientSecret: provider.clientSecret } : {}),
  };
};
