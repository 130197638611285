import { useCallback, useState } from 'react';

import { LocalStorage, useDynamicContext } from '@dynamic-labs/sdk-react-core';

import { ACTIVE_ORG_AND_PROJ } from '../../utils/constants';
import { lastVerifiedCredential } from '../../utils/lastVerifiedCredential';

interface ActiveOrgAndProj {
  organizationId: string;
  projectId: string;
}

export const useStorageActiveOrgAndProject = () => {
  const { user } = useDynamicContext();
  const verifiedCredential = lastVerifiedCredential(user);

  const walletPublicKey = verifiedCredential?.address || '';
  const initialData =
    LocalStorage.getFromLS<Record<string, ActiveOrgAndProj>>(
      ACTIVE_ORG_AND_PROJ,
    );

  const [currentOrganizationId, setCurrentOrganizationId] = useState<
    string | undefined
  >(initialData?.[walletPublicKey]?.organizationId);
  const [currentProjectId, setCurrentProjectId] = useState<string | undefined>(
    initialData?.[walletPublicKey]?.projectId,
  );

  const setActiveOrganizationId = useCallback(
    (organizationId: string | undefined) => {
      if (!organizationId) return;

      setCurrentOrganizationId(organizationId);
      LocalStorage.setToLS(ACTIVE_ORG_AND_PROJ, {
        ...initialData,
        [walletPublicKey]: { organizationId, projectId: currentProjectId },
      });
    },
    [currentProjectId, initialData, walletPublicKey],
  );

  const setActiveProjectId = useCallback(
    (projectId: string | undefined) => {
      if (!projectId) return;

      setCurrentProjectId(projectId);
      LocalStorage.setToLS(ACTIVE_ORG_AND_PROJ, {
        ...initialData,
        [walletPublicKey]: { organizationId: currentOrganizationId, projectId },
      });
    },
    [initialData, walletPublicKey, currentOrganizationId],
  );

  return {
    activeOrganizationId: currentOrganizationId,
    activeProjectId: currentProjectId,
    setActiveOrganizationId,
    setActiveProjectId,
  };
};
