import { useFlags } from 'launchdarkly-react-client-sdk';

import { UpsellBanner } from '../../components/UpsellBanner';
import styles from '../Configurations/configurations.module.css';
import { chainsData } from '../../data/integrations';
import { isIntegrationEnabled } from '../Configurations/utils';
import SingleChain from '../Configurations/SingleChain';
import { useSettingsContext } from '../../context/SettingsContext';
import { useSubscriptionContext } from '../../context/SubscriptionContext';
import { useSubscriptionLock } from '../../hooks/useSubscriptionLock';

export const ChainsAndNetworksRoute = () => {
  const { enableBitcoinIntegration } = useFlags();

  const {
    initialSettings: {
      live: { chains: liveChains },
      sandbox: { chains: sandboxChains },
    },
  } = useSettingsContext();

  const { subscription } = useSubscriptionContext();
  const { shouldShowTrialBanner } = useSubscriptionLock();

  const { trialDaysLeft } = subscription || {};

  return (
    <section className='pb-6'>
      <div className='w-fit'>
        {shouldShowTrialBanner && (
          <UpsellBanner daysLeft={trialDaysLeft} className={styles.banner} />
        )}

        <div className={styles.container}>
          <div className={styles.chains__container}>
            {chainsData.map(
              ({
                id,
                name,
                content,
                url,
                Icon,
                chain,
                walletDescription,
                title,
                comingSoon,
                networkMessage,
                requireCustomRpcUrl,
              }) => {
                if (
                  !isIntegrationEnabled(name, {
                    enableBitcoinIntegration,
                  })
                ) {
                  return null;
                }
                return (
                  <SingleChain
                    title={title}
                    key={`${id}-${name}`}
                    name={name}
                    content={content}
                    url={url}
                    Icon={Icon}
                    chain={chain}
                    walletDescription={walletDescription}
                    liveSettings={liveChains}
                    sandboxSettings={sandboxChains}
                    comingSoon={comingSoon}
                    networkMessage={networkMessage}
                    requireCustomRpcUrl={requireCustomRpcUrl}
                  />
                );
              },
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
