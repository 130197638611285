import { useState } from 'react';

import { Field, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { object, string } from 'yup';

import { Project } from '@dynamic-labs/sdk-api';

import { logger } from '../../../../services/logger';
import Button from '../../../../components/Button';
import { projectsApi } from '../../../../services/api';
import Input from '../../../../components/Input';
import { FormErrors } from '../../../Dashboard/Forms/CreateProjectForm/FormErrors';
import { validateProjectName } from '../../../Dashboard/Forms/CreateProjectForm/helpers';

import styles from './UpdateProjectForm.module.css';

type FormValues = {
  projectName: string;
};

type UpdateProjectFormProps = {
  cancelAction?: () => void;
  cancelText: string;
  onProjectUpdate(): void;
  projectId: string;
  projectName: string;
  projects: Project[];
  submitAction?: () => void;
  submitText: string;
};

export const UpdateProjectForm = ({
  projectId,
  onProjectUpdate,
  submitText,
  cancelText,
  submitAction,
  cancelAction,
  projects: existingProjects,
  projectName,
}: UpdateProjectFormProps) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const onSubmit = async (values: FormValues) => {
    try {
      setLoading(true);

      await projectsApi.updateProject({
        projectId,
        updateProjectRequest: {
          name: values.projectName,
        },
      });

      onProjectUpdate();
    } catch (e) {
      logger.error(e);
    } finally {
      if (submitAction) submitAction();
      setLoading(false);
    }
  };

  const validationSchema = object().shape({
    projectName: string()
      .required('required')
      .test('duplicated_name', '', (value, context) =>
        validateProjectName(value, context, existingProjects),
      ),
  });

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{ projectName }}
      validationSchema={validationSchema}
    >
      {({ errors, touched }) => (
        <Form>
          <FormErrors
            errors={errors}
            showErrors={
              !!Object.keys(touched).length && !!Object.keys(errors).length
            }
          />

          <Field
            as={Input}
            name='projectName'
            id='projectName'
            label={t('create_project.projectNameLabel')}
            error={!!errors.projectName && !!touched.projectName}
          />

          <div className={styles.buttons}>
            <Button
              type='button'
              large
              variant='secondary'
              onClick={cancelAction}
            >
              {cancelText}
            </Button>
            <Button type='submit' loading={loading} large>
              {submitText}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
