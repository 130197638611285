import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { Spinner } from '../../../../../components/Spinner';
import { useEnvironmentsContext } from '../../../../../context/EnvironmentsContext';
import Button from '../../../../../components/Button';
import { Typography } from '../../../../../components/Typography/Typography';
import bg from '../../../../../../assets/quick-guide-last-step-bg.svg';
import styles from '../../../../../components/Accordion/Accordion.module.css';
import { useEnvironmentVisitorsQuery } from '../../../../../hooks/useEnvironmentVisitorsQuery';

export const DeployAndConnectWalletStep = () => {
  const { activeEnvironmentType, activeEnvironment } = useEnvironmentsContext();
  const {
    data: activeEnvironmentCounters,
    refetch: refetchCounters,
    isLoading,
  } = useEnvironmentVisitorsQuery({
    environmentId: activeEnvironment?.id,
  });
  const { t } = useTranslation();

  const counterTitle = useMemo(() => {
    if (activeEnvironmentType === 'sandbox') {
      return t('overview.after_install.first_subtitle');
    }

    return t('overview.after_install.second_subtitle');
  }, [activeEnvironmentType, t]);

  return (
    <div className={styles['accordion-item--last']}>
      <div className={styles['accordion-item--last-content']}>
        <Typography variant='paragraph-2' className={styles.label}>
          {t('overview.steps.last.content')}
        </Typography>
        <div>
          <Typography variant='paragraph-2' className='!text-gray-1'>
            {counterTitle}
          </Typography>
          <Typography
            variant='numbers'
            className={styles['accordion-item--last-content__counter']}
          >
            {isLoading ? (
              <Spinner />
            ) : (
              activeEnvironmentCounters?.users.count || 0
            )}
          </Typography>
        </div>
        <Button
          variant='secondary'
          onClick={() => refetchCounters()}
          testId='refresh-button'
        >
          {t('overview.steps.last.refresh')}
        </Button>
      </div>
      <img className={styles['accordion-item--last-img']} src={bg} alt='' />
    </div>
  );
};
