/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JwtVerifiedCredentialFormatEnum,
    JwtVerifiedCredentialFormatEnumFromJSON,
    JwtVerifiedCredentialFormatEnumFromJSONTyped,
    JwtVerifiedCredentialFormatEnumToJSON,
} from './JwtVerifiedCredentialFormatEnum';
import {
    NameServiceData,
    NameServiceDataFromJSON,
    NameServiceDataFromJSONTyped,
    NameServiceDataToJSON,
} from './NameServiceData';
import {
    ProviderEnum,
    ProviderEnumFromJSON,
    ProviderEnumFromJSONTyped,
    ProviderEnumToJSON,
} from './ProviderEnum';
import {
    WalletAdditionalAddress,
    WalletAdditionalAddressFromJSON,
    WalletAdditionalAddressFromJSONTyped,
    WalletAdditionalAddressToJSON,
} from './WalletAdditionalAddress';
import {
    WalletProperties,
    WalletPropertiesFromJSON,
    WalletPropertiesFromJSONTyped,
    WalletPropertiesToJSON,
} from './WalletProperties';
import {
    WalletProviderEnum,
    WalletProviderEnumFromJSON,
    WalletProviderEnumFromJSONTyped,
    WalletProviderEnumToJSON,
} from './WalletProviderEnum';

/**
 * 
 * @export
 * @interface JwtVerifiedCredential
 */
export interface JwtVerifiedCredential {
    /**
     * Valid blockchain wallet address, must be an alphanumeric string without any special characters
     * @type {string}
     * @memberof JwtVerifiedCredential
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof JwtVerifiedCredential
     */
    chain?: string;
    /**
     * 
     * @type {string}
     * @memberof JwtVerifiedCredential
     */
    refId?: string;
    /**
     * 
     * @type {string}
     * @memberof JwtVerifiedCredential
     */
    signerRefId?: string;
    /**
     * 
     * @type {string}
     * @memberof JwtVerifiedCredential
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof JwtVerifiedCredential
     */
    id: string;
    /**
     * 
     * @type {NameServiceData}
     * @memberof JwtVerifiedCredential
     */
    nameService?: NameServiceData;
    /**
     * This is used to publicly identify a verified credential in a human-friendly way. For example, this will be the email address if credential format=email.
     * @type {string}
     * @memberof JwtVerifiedCredential
     */
    publicIdentifier?: string;
    /**
     * 
     * @type {string}
     * @memberof JwtVerifiedCredential
     */
    walletName?: string;
    /**
     * 
     * @type {WalletProviderEnum}
     * @memberof JwtVerifiedCredential
     */
    walletProvider?: WalletProviderEnum;
    /**
     * 
     * @type {WalletProperties}
     * @memberof JwtVerifiedCredential
     */
    walletProperties?: WalletProperties;
    /**
     * 
     * @type {JwtVerifiedCredentialFormatEnum}
     * @memberof JwtVerifiedCredential
     */
    format: JwtVerifiedCredentialFormatEnum;
    /**
     * 
     * @type {ProviderEnum}
     * @memberof JwtVerifiedCredential
     */
    oauthProvider?: ProviderEnum;
    /**
     * 
     * @type {string}
     * @memberof JwtVerifiedCredential
     */
    oauthUsername?: string;
    /**
     * 
     * @type {string}
     * @memberof JwtVerifiedCredential
     */
    oauthDisplayName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof JwtVerifiedCredential
     */
    oauthAccountId?: string | null;
    /**
     * This field contains the phone number in the country. This could also contain the area code within a country.
     * @type {string}
     * @memberof JwtVerifiedCredential
     */
    phoneNumber?: string;
    /**
     * This field contains the phone international country code. See https://countrycode.org/
     * @type {string}
     * @memberof JwtVerifiedCredential
     */
    phoneCountryCode?: string;
    /**
     * ISO-3166 two-character country code. See https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes
     * @type {string}
     * @memberof JwtVerifiedCredential
     */
    isoCountryCode?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof JwtVerifiedCredential
     */
    oauthAccountPhotos?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof JwtVerifiedCredential
     */
    oauthEmails?: Array<string>;
    /**
     * This object contains JSON metadata for a social-based verified credential. It may contain data about the user that does not fit into the other structured fields, and could include arbitrary fields about the user from the oauth provider's API.
     * @type {object}
     * @memberof JwtVerifiedCredential
     */
    oauthMetadata?: object;
    /**
     * This will only be provided in the responses for GET /users/{userId}.Previous user IDs that owned this verified credential and was tranfered to the current user ID.
     * @type {Array<string>}
     * @memberof JwtVerifiedCredential
     */
    previousUsers?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof JwtVerifiedCredential
     */
    embeddedWalletId?: string | null;
    /**
     * 
     * @type {Array<WalletAdditionalAddress>}
     * @memberof JwtVerifiedCredential
     */
    walletAdditionalAddresses?: Array<WalletAdditionalAddress>;
    /**
     * This timestamp indicates the last time this verified wallet was either connected to the user account or selected to become the primary wallet on the account.
     * @type {Date}
     * @memberof JwtVerifiedCredential
     */
    lastSelectedAt?: Date;
}

export function JwtVerifiedCredentialFromJSON(json: any): JwtVerifiedCredential {
    return JwtVerifiedCredentialFromJSONTyped(json, false);
}

export function JwtVerifiedCredentialFromJSONTyped(json: any, ignoreDiscriminator: boolean): JwtVerifiedCredential {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'address': !exists(json, 'address') ? undefined : json['address'],
        'chain': !exists(json, 'chain') ? undefined : json['chain'],
        'refId': !exists(json, 'refId') ? undefined : json['refId'],
        'signerRefId': !exists(json, 'signerRefId') ? undefined : json['signerRefId'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'id': json['id'],
        'nameService': !exists(json, 'name_service') ? undefined : NameServiceDataFromJSON(json['name_service']),
        'publicIdentifier': !exists(json, 'public_identifier') ? undefined : json['public_identifier'],
        'walletName': !exists(json, 'wallet_name') ? undefined : json['wallet_name'],
        'walletProvider': !exists(json, 'wallet_provider') ? undefined : WalletProviderEnumFromJSON(json['wallet_provider']),
        'walletProperties': !exists(json, 'wallet_properties') ? undefined : WalletPropertiesFromJSON(json['wallet_properties']),
        'format': JwtVerifiedCredentialFormatEnumFromJSON(json['format']),
        'oauthProvider': !exists(json, 'oauth_provider') ? undefined : ProviderEnumFromJSON(json['oauth_provider']),
        'oauthUsername': !exists(json, 'oauth_username') ? undefined : json['oauth_username'],
        'oauthDisplayName': !exists(json, 'oauth_display_name') ? undefined : json['oauth_display_name'],
        'oauthAccountId': !exists(json, 'oauth_account_id') ? undefined : json['oauth_account_id'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'phoneCountryCode': !exists(json, 'phoneCountryCode') ? undefined : json['phoneCountryCode'],
        'isoCountryCode': !exists(json, 'isoCountryCode') ? undefined : json['isoCountryCode'],
        'oauthAccountPhotos': !exists(json, 'oauth_account_photos') ? undefined : json['oauth_account_photos'],
        'oauthEmails': !exists(json, 'oauth_emails') ? undefined : json['oauth_emails'],
        'oauthMetadata': !exists(json, 'oauth_metadata') ? undefined : json['oauth_metadata'],
        'previousUsers': !exists(json, 'previous_users') ? undefined : json['previous_users'],
        'embeddedWalletId': !exists(json, 'embedded_wallet_id') ? undefined : json['embedded_wallet_id'],
        'walletAdditionalAddresses': !exists(json, 'wallet_additional_addresses') ? undefined : ((json['wallet_additional_addresses'] as Array<any>).map(WalletAdditionalAddressFromJSON)),
        'lastSelectedAt': !exists(json, 'lastSelectedAt') ? undefined : (new Date(json['lastSelectedAt'])),
    };
}

export function JwtVerifiedCredentialToJSON(value?: JwtVerifiedCredential | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'address': value.address,
        'chain': value.chain,
        'refId': value.refId,
        'signerRefId': value.signerRefId,
        'email': value.email,
        'id': value.id,
        'name_service': NameServiceDataToJSON(value.nameService),
        'public_identifier': value.publicIdentifier,
        'wallet_name': value.walletName,
        'wallet_provider': WalletProviderEnumToJSON(value.walletProvider),
        'wallet_properties': WalletPropertiesToJSON(value.walletProperties),
        'format': JwtVerifiedCredentialFormatEnumToJSON(value.format),
        'oauth_provider': ProviderEnumToJSON(value.oauthProvider),
        'oauth_username': value.oauthUsername,
        'oauth_display_name': value.oauthDisplayName,
        'oauth_account_id': value.oauthAccountId,
        'phoneNumber': value.phoneNumber,
        'phoneCountryCode': value.phoneCountryCode,
        'isoCountryCode': value.isoCountryCode,
        'oauth_account_photos': value.oauthAccountPhotos,
        'oauth_emails': value.oauthEmails,
        'oauth_metadata': value.oauthMetadata,
        'previous_users': value.previousUsers,
        'embedded_wallet_id': value.embeddedWalletId,
        'wallet_additional_addresses': value.walletAdditionalAddresses === undefined ? undefined : ((value.walletAdditionalAddresses as Array<any>).map(WalletAdditionalAddressToJSON)),
        'lastSelectedAt': value.lastSelectedAt === undefined ? undefined : (value.lastSelectedAt.toISOString()),
    };
}

