/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Dispatch, SetStateAction, useState } from 'react';

import { Field, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { object, string } from 'yup';

import {
  WhenToImplementEnum,
  Organization,
  Project,
} from '@dynamic-labs/sdk-api';
import { VALID_URL_REGEXP } from 'apps/dashboard/src/app/utils/constants';
import { UserProfile } from '@dynamic-labs/sdk-react-core';
import { Typography } from 'apps/dashboard/src/app/components/Typography';
import { Select } from 'apps/dashboard/src/app/components/Select';

import { logger } from '../../../../services/logger';
import Button from '../../../../components/Button';
import { organizationsApi, projectsApi } from '../../../../services/api';
import Input from '../../../../components/Input';
import { FormErrors } from '../FormErrors/form-errors';
import Checkbox from '../../../../components/Checkbox';
import { TermsAndConditions } from '../../TermsAndConditions';
import {
  DEFAULT_ENV,
  useEnvironmentsContext,
} from '../../../../context/EnvironmentsContext';
import { useDashboardContext } from '../../../../context/DashboardContext';

import { validateOrgName, buildErrorsInfo } from './helpers';
import styles from './create-organization-form.module.css';

interface FormValues {
  implementationTimeline?: string;
  organizationName: string;
  websiteUrl: string;
}

interface Props {
  cancelAction?: () => void;
  cancelText?: string;
  disabled?: boolean;
  setUserProjects: Dispatch<SetStateAction<Project[]>>;
  submitAction?: (args: {
    organization: Organization;
    project: Project;
  }) => void;
  submitText: string;
  user?: UserProfile;
  userOrganizations?: Organization[];
  userProjects?: Project[];
}

const CreateOrganizationForm = ({
  setUserProjects,
  userOrganizations = [],
  userProjects = [],
  submitText,
  cancelText,
  submitAction,
  cancelAction,
  disabled,
  user,
}: Props) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const [agreedWithTermsAndConditions, setAggreedWithTermsAndConditions] =
    useState(false);
  const { setActiveEnvironmentType } = useEnvironmentsContext();

  const isOnboarding = userOrganizations.length === 0;

  const { fetchUserOrganizations, setActiveOrganizationId } =
    useDashboardContext();

  const onSubmit = async (values: FormValues) => {
    try {
      setLoading(true);
      const { websiteUrl, organizationName, implementationTimeline } = values;
      const { organization } = await organizationsApi.createOrganization({
        organizationRequest: {
          implementationTimeline: !implementationTimeline
            ? undefined
            : (implementationTimeline as WhenToImplementEnum),
          name: organizationName,
          websiteUrl: !websiteUrl ? undefined : websiteUrl,
        },
      });

      if (!organization?.id) return;

      const { project } = await projectsApi.createProject({
        organizationId: organization.id,
        projectRequest: {
          name: organizationName, // first project name is the organization
        },
      });

      if (project) {
        fetchUserOrganizations();

        setActiveOrganizationId(organization.id);
        setUserProjects([...userProjects, project as Project]);
      }

      submitAction?.({ organization, project: project as Project });
      setActiveEnvironmentType(DEFAULT_ENV);
    } catch (e) {
      logger.error(e);
    } finally {
      setLoading(false);
    }
  };

  const validationSchema = object().shape({
    organizationName: string()
      .trim()
      .required('required')
      .matches(
        /^(?=\S)[a-zA-Z0-9 _.,:!'?&%@\\/-]+(?<=\S)$/,
        'invalidOrganizationNameError',
      )
      .test('duplicated_name', '', (value, context) =>
        validateOrgName(value, context, userOrganizations),
      ),
    websiteUrl: string().trim().lowercase().matches(VALID_URL_REGEXP),
    whenToImplement: string(),
  });

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        implementationTimeline: '',
        organizationName: user?.username
          ? `${user?.username}${t('create_org.defaultOrg')}`
          : '',
        websiteUrl: '',
      }}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={validationSchema}
    >
      {({ errors, touched, values, setFieldValue }) => (
        <Form>
          <FormErrors
            errors={buildErrorsInfo(errors)}
            showErrors={
              !!Object.keys(touched).length && !!Object.keys(errors).length
            }
          />
          <Field
            autoFocus
            as={Input}
            disabled={disabled}
            name='organizationName'
            id='organizationName'
            label={t('create_org.orgNameLabel')}
            error={!!errors.organizationName && !!touched.organizationName}
          />
          <Input
            label={t('create_org.qualifying_fields.url_input_placeholder')}
            name='websiteUrl'
            id='websiteUrl'
            disabled={disabled}
            error={!!errors.websiteUrl && !!touched.websiteUrl}
            value={values.websiteUrl}
            onChange={(e) =>
              setFieldValue('websiteUrl', e.target.value.toLowerCase())
            }
          />
          {isOnboarding && (
            <Typography
              variant='paragraph-2'
              weight='regular'
              className='whitespace-nowrap !text-gray-1 mt-4 mb-4'
            >
              {t('create_org.first_org.when_to_implement')}
            </Typography>
          )}
          {isOnboarding && (
            <Select
              dropdownTriggerRootClassName='min-h-[48px]'
              className='select-emailOrWallet min-w-[100px]'
              onSelect={(e) => setFieldValue('implementationTimeline', e)}
              placeholder={t(
                'create_org.first_org.implementation_options_placeholder',
              )}
              options={[
                WhenToImplementEnum.Yesterday,
                WhenToImplementEnum.NextTwoWeeks,
                WhenToImplementEnum.NextTwoMonths,
                WhenToImplementEnum.JustBrowsing,
              ].map((option: string) => (
                <Select.Item value={option}>
                  <Typography variant='paragraph-2' weight='medium'>
                    {t(
                      `create_org.first_org.implementation_options.${
                        option as WhenToImplementEnum
                      }`,
                    )}
                  </Typography>
                </Select.Item>
              ))}
              value={values.implementationTimeline}
            />
          )}

          {isOnboarding && (
            <Checkbox
              className='mt-3'
              id='tec-checkbox'
              checked={agreedWithTermsAndConditions}
              onChange={() =>
                setAggreedWithTermsAndConditions(!agreedWithTermsAndConditions)
              }
              label={<TermsAndConditions />}
            />
          )}
          {isOnboarding ? (
            <Button
              type='submit'
              className={styles.button}
              loading={loading}
              large
              disabled={
                values.organizationName === '' ||
                (isOnboarding && !agreedWithTermsAndConditions) ||
                values.implementationTimeline === ''
              }
            >
              {submitText}
            </Button>
          ) : (
            <div className={styles.buttons}>
              {cancelText && (
                <Button
                  type='button'
                  large
                  variant='secondary'
                  onClick={cancelAction}
                >
                  {cancelText}
                </Button>
              )}
              {!disabled && (
                <Button type='submit' loading={loading} large>
                  {submitText}
                </Button>
              )}
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default CreateOrganizationForm;
