import { useEffect, useState } from 'react';

import { t } from 'i18next';
import { Link } from 'react-router-dom';

import { CheckIcon } from '@dynamic-labs/sdk-react-core';
import { capitalize } from '@dynamic-labs/northstar';

import { Typography } from '../../../../../components/Typography';
import { SingleKey } from '../../../../../components/SingleKey';
import Button from '../../../../../components/Button';
import styles from '../../../../../components/Accordion/Accordion.module.css';
import { useEnvironmentsContext } from '../../../../../context/EnvironmentsContext';
import { ROUTES } from '../../../../../components/Navigation/data';

const codeSnippetTemplate = `import { DynamicContextProvider, DynamicWidget } from '@dynamic-labs/sdk-react-core';
import { EthereumWalletConnectors } from "@dynamic-labs/ethereum";

const App = () => (
  <DynamicContextProvider
    settings={{
      environmentId: {{envId}},
      walletConnectors: [ EthereumWalletConnectors ],
    }}>
    <DynamicWidget />
  </DynamicContextProvider>
);

export default App;
`;

export const CopySnippetStep = ({
  onStepCompleted,
}: {
  onStepCompleted: (step: number) => void;
}) => {
  const { activeEnvironment, activeEnvironmentType } = useEnvironmentsContext();

  const [codeSnippet, setCodeSnippet] = useState<string>('');
  useEffect(() => {
    if (activeEnvironment?.id) {
      setCodeSnippet(
        codeSnippetTemplate.replace('{{envId}}', `'${activeEnvironment.id}'`),
      );
    } else {
      setCodeSnippet(
        codeSnippetTemplate.replace(
          '{{envId}}',
          t('overview.steps.second.environmentid_placeholder'),
        ),
      );
    }
  }, [activeEnvironment, codeSnippet]);

  return (
    <div className={styles['stepper-tabs']}>
      <Typography
        variant='subtitle'
        className={styles['stepper-tabs__subtitle']}
      >
        {t<string>('overview.steps.second.subtitle.first').replace(
          '{{env}}',
          capitalize(activeEnvironmentType),
        )}
        <Link
          to={ROUTES.api}
          className={styles['stepper-tabs__subtitle__link']}
        >
          {t<string>('overview.steps.second.subtitle.link')}
        </Link>
        {t<string>('overview.steps.second.subtitle.second')}
      </Typography>
      <SingleKey
        extended
        highlightCodeSnippet
        keyValue={codeSnippet}
        className={`${styles['single-key']} my-3`}
      />
      <Button
        variant='secondary'
        leading={<CheckIcon />}
        onClick={() => onStepCompleted(2)}
        testId='done-cta'
      >
        {t<string>('overview.steps.done_button')}
      </Button>
    </div>
  );
};
