import { FC, PropsWithChildren } from 'react';

import { Navigate } from 'react-router-dom';

import { RoleEnum } from '@dynamic-labs/sdk-api';

import { useDashboardRole } from '../../hooks/useDashboardRole';

type ProtectedRouteProps = {
  role: RoleEnum;
};

export const ProtectedRoute: FC<PropsWithChildren<ProtectedRouteProps>> = ({
  role,
  children,
}) => {
  const { checkForAccess } = useDashboardRole();

  if (checkForAccess(role)) {
    return children;
  }

  return <Navigate to='/' />;
};
