/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BadRequest,
    BadRequestFromJSON,
    BadRequestToJSON,
    EnvironmentVisitorsResponse,
    EnvironmentVisitorsResponseFromJSON,
    EnvironmentVisitorsResponseToJSON,
    EnvironmentsResponse,
    EnvironmentsResponseFromJSON,
    EnvironmentsResponseToJSON,
    Forbidden,
    ForbiddenFromJSON,
    ForbiddenToJSON,
    InternalServerError,
    InternalServerErrorFromJSON,
    InternalServerErrorToJSON,
    KeyResponse,
    KeyResponseFromJSON,
    KeyResponseToJSON,
    MethodNotAllowed,
    MethodNotAllowedFromJSON,
    MethodNotAllowedToJSON,
    ProjectEnvironment,
    ProjectEnvironmentFromJSON,
    ProjectEnvironmentToJSON,
    ProjectSettings,
    ProjectSettingsFromJSON,
    ProjectSettingsToJSON,
    Unauthorized,
    UnauthorizedFromJSON,
    UnauthorizedToJSON,
    UnprocessableEntity,
    UnprocessableEntityFromJSON,
    UnprocessableEntityToJSON,
} from '../models';

export interface DeleteEnvironmentByIdRequest {
    environmentId: string;
}

export interface GetEnvironmentByIdRequest {
    environmentId: string;
}

export interface GetEnvironmentsByProjectIdRequest {
    projectId: string;
}

export interface GetKeysForEnvironmentRequest {
    environmentId: string;
}

export interface GetVisitorsCountByEnvironmentIdRequest {
    environmentId: string;
}

export interface UpdateProjectSettingsRequest {
    environmentId: string;
    projectSettings?: ProjectSettings;
}

/**
 * 
 */
export class EnvironmentsApi extends runtime.BaseAPI {

    /**
     * Deletes an environment by ID
     */
    async deleteEnvironmentByIdRaw(requestParameters: DeleteEnvironmentByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling deleteEnvironmentById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/environments/{environmentId}`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes an environment by ID
     */
    async deleteEnvironmentById(requestParameters: DeleteEnvironmentByIdRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteEnvironmentByIdRaw(requestParameters, initOverrides);
    }

    /**
     * Find an environment by ID
     */
    async getEnvironmentByIdRaw(requestParameters: GetEnvironmentByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProjectEnvironment>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling getEnvironmentById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/environments/{environmentId}`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectEnvironmentFromJSON(jsonValue));
    }

    /**
     * Find an environment by ID
     */
    async getEnvironmentById(requestParameters: GetEnvironmentByIdRequest, initOverrides?: RequestInit): Promise<ProjectEnvironment> {
        const response = await this.getEnvironmentByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Live and Sandbox environments by projectId
     */
    async getEnvironmentsByProjectIdRaw(requestParameters: GetEnvironmentsByProjectIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<EnvironmentsResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getEnvironmentsByProjectId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{projectId}/environments`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EnvironmentsResponseFromJSON(jsonValue));
    }

    /**
     * Get Live and Sandbox environments by projectId
     */
    async getEnvironmentsByProjectId(requestParameters: GetEnvironmentsByProjectIdRequest, initOverrides?: RequestInit): Promise<EnvironmentsResponse> {
        const response = await this.getEnvironmentsByProjectIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get keys for an environment given environmentId
     */
    async getKeysForEnvironmentRaw(requestParameters: GetKeysForEnvironmentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<KeyResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling getKeysForEnvironment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/environments/{environmentId}/keys`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => KeyResponseFromJSON(jsonValue));
    }

    /**
     * Get keys for an environment given environmentId
     */
    async getKeysForEnvironment(requestParameters: GetKeysForEnvironmentRequest, initOverrides?: RequestInit): Promise<KeyResponse> {
        const response = await this.getKeysForEnvironmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the unique number of visitors for an environment by environment ID
     */
    async getVisitorsCountByEnvironmentIdRaw(requestParameters: GetVisitorsCountByEnvironmentIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<EnvironmentVisitorsResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling getVisitorsCountByEnvironmentId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/environments/{environmentId}/statistics/visitors`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EnvironmentVisitorsResponseFromJSON(jsonValue));
    }

    /**
     * Get the unique number of visitors for an environment by environment ID
     */
    async getVisitorsCountByEnvironmentId(requestParameters: GetVisitorsCountByEnvironmentIdRequest, initOverrides?: RequestInit): Promise<EnvironmentVisitorsResponse> {
        const response = await this.getVisitorsCountByEnvironmentIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates the environment settings
     */
    async updateProjectSettingsRaw(requestParameters: UpdateProjectSettingsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProjectSettings>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling updateProjectSettings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/environments/{environmentId}`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectSettingsToJSON(requestParameters.projectSettings),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectSettingsFromJSON(jsonValue));
    }

    /**
     * Updates the environment settings
     */
    async updateProjectSettings(requestParameters: UpdateProjectSettingsRequest, initOverrides?: RequestInit): Promise<ProjectSettings> {
        const response = await this.updateProjectSettingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
