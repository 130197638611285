import { ReactElement } from 'react';

import { ChainEnum } from '@dynamic-labs/sdk-api';
import {
  SolanaIcon,
  EthereumIcon,
  FlowIcon,
  BitcoinIcon,
  AlgorandIcon,
  StarknetIcon,
  CosmosIcon,
} from '@dynamic-labs/iconic';

export const chainToIconMap: Record<ChainEnum, ReactElement> = {
  [ChainEnum.Eth]: <EthereumIcon />,
  [ChainEnum.Evm]: <EthereumIcon />,
  [ChainEnum.Sol]: <SolanaIcon />,
  [ChainEnum.Flow]: <FlowIcon />,
  [ChainEnum.Btc]: <BitcoinIcon />,
  [ChainEnum.Algo]: <AlgorandIcon />,
  [ChainEnum.Stark]: <StarknetIcon />,
  [ChainEnum.Cosmos]: <CosmosIcon />,
};

export const chainToNameMap: Record<ChainEnum, string> = {
  [ChainEnum.Eth]: 'Ethereum',
  [ChainEnum.Evm]: 'Ethereum',
  [ChainEnum.Sol]: 'Solana',
  [ChainEnum.Flow]: 'Flow',
  [ChainEnum.Btc]: 'Bitcoin',
  [ChainEnum.Algo]: 'Algorand',
  [ChainEnum.Stark]: 'Starknet',
  [ChainEnum.Cosmos]: 'Cosmos',
};
