import { Dispatch, SetStateAction } from 'react';

import { t } from 'i18next';

import { Webhook } from '@dynamic-labs/sdk-api';

import Portal from '../../../../components/Portal';
import Header from '../../../../components/Portal/Header';
import { WebhookForm } from '../WebhookForm';

import styles from './EditWebhookModal.module.css';

type Props = {
  setShowModal: Dispatch<SetStateAction<boolean>>;
  webhook: Webhook;
};

export const EditWebhookModal = ({ setShowModal, webhook }: Props) => (
  <Portal
    backdropClassName='!z-30'
    handleClose={() => setShowModal(false)}
    className={styles.portal}
  >
    <Header
      handleClose={() => setShowModal(false)}
      headingText={t<string>('webhooks.update_webhook_modal.title')}
    />
    <WebhookForm setShowModal={setShowModal} webhook={webhook} />
  </Portal>
);
