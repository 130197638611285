import { useTranslation } from 'react-i18next';

import { MFADeviceType } from '@dynamic-labs/sdk-api';

import { Paper } from '../../../components/Paper';
import { Typography } from '../../../components/Typography';
import { Toggle } from '../../../components/Toggle';
import { InputLabel } from '../../../components/InputLabel';
import { useDashboardContext } from '../../../context/DashboardContext';

import styles from './SecurityContent.module.css';
import { useOrganizationMfaSettings } from './useOrganizationMfaSettings';

export const SecurityContent = ({
  organizationId,
}: {
  organizationId: string;
}) => {
  const { t } = useTranslation();

  const { mfaSettings, toggleMfaSettings } =
    useOrganizationMfaSettings(organizationId);

  const getLabelForMfaDeviceType = (mfaDeviceType: MFADeviceType) => {
    switch (mfaDeviceType) {
      case 'totp':
      default:
        return t('organization_security.login_requirements.mfa.totp');
    }
  };

  return (
    <Paper className={styles.paper}>
      <div className={styles.wrapper}>
        <div className={`${styles.panel} ${styles['panel--left']}`}>
          <div className={`${styles.inline} ${styles['inline--margin']}`}>
            <Typography variant='title' className={styles.title}>
              {t('organization_security.login_requirements.title')}
            </Typography>
          </div>
        </div>
      </div>
      <div className={styles.divider} />
      <div className={styles.mfa}>
        <Typography variant='subtitle' className={styles.subtitle}>
          {t('organization_security.login_requirements.mfa.title')}
        </Typography>
        <Typography variant='paragraph-2' className={styles.description}>
          {t('organization_security.login_requirements.mfa.description')}
        </Typography>
        <Paper className={`${styles.paper} ${styles.toggle}`}>
          {mfaSettings.map((setting) => (
            <>
              <Toggle
                checked={!!setting.enabledAt}
                handleChange={() => toggleMfaSettings(setting)}
                id={setting.mfaDeviceType}
                ariaLabel={setting.mfaDeviceType}
              />
              <InputLabel
                className={styles.toggleLabel}
                htmlFor={setting.mfaDeviceType}
              >
                {getLabelForMfaDeviceType(setting.mfaDeviceType)}
              </InputLabel>
            </>
          ))}
        </Paper>
      </div>
    </Paper>
  );
};

export const SecurityContentWrapper = () => {
  const { activeOrganizationId } = useDashboardContext();

  if (!activeOrganizationId) {
    return null;
  }

  return <SecurityContent organizationId={activeOrganizationId} />;
};
