import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { Icon } from '../Icon';
import { Typography } from '../Typography';

import styles from './Tag.module.scss';

type TagProps = {
  className?: string;
  color: 'primary' | 'transparent' | 'gray';
  icon?: ReactElement;
  iconPosition?: 'left' | 'right';
  size: 'large' | 'medium';
  text: string;
};

export const Tag: FC<TagProps> = ({
  text,
  className,
  iconPosition = 'right',
  icon,
  size,
  color,
}) => (
  <div
    className={classNames(
      className,
      styles.tag,
      styles[`tag--${color}`],
      styles[`tag--${size}`],
      {
        [styles['tag--icon-left']]: iconPosition === 'left',
      },
    )}
  >
    <Typography
      variant={size === 'large' ? 'paragraph-1' : 'paragraph-0'}
      color='inherit'
      className={classNames({
        [styles['tag__text-large']]: size === 'large',
      })}
    >
      {text}
    </Typography>
    {icon && <Icon icon={icon} size={size === 'large' ? 16 : 12} />}
  </div>
);
