import { CSSProperties, FC } from 'react';

import classNames from 'classnames';

import styles from './Skeleton.module.scss';

type SkeletonProps = {
  className?: string;
  style?: CSSProperties;
};

export const Skeleton: FC<SkeletonProps> = ({ className, style }) => (
  <span
    className={classNames(styles.skeleton, className)}
    style={style}
    data-testid='loading-skeleton'
  />
);
