import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  EnvironmentEnum,
  EnvironmentsResponseEnvironments,
  ProjectEnvironment,
} from '@dynamic-labs/sdk-api';
import { useLocalStorage } from '@dynamic-labs/sdk-react-core';
import { useGetEnvironmentsByProjectId } from '@dynamic-labs/redcoast-query';

import { useDashboardContext } from '../DashboardContext';
import { ACTIVE_ENV } from '../../utils/constants';
import { ROUTES } from '../../components/Navigation/data';
import { useSubscriptionContext } from '../SubscriptionContext';
import { logger } from '../../services/logger';

import { validateActiveEnv } from './validators';

export interface EnvironmentsContextValue {
  activeEnvironment: ProjectEnvironment | undefined;
  activeEnvironmentType: EnvironmentEnum;
  docsUrl: string;
  environments: EnvironmentsResponseEnvironments | undefined;
  setActiveEnvironmentType: Dispatch<SetStateAction<EnvironmentEnum>>;
}

export const DEFAULT_ENV = EnvironmentEnum.Sandbox;

export const EnvironmentsContext = createContext<
  EnvironmentsContextValue | undefined
>(undefined);

export const EnvironmentsContextProvider: FC<PropsWithChildren<object>> = ({
  children,
}) => {
  const [activeEnvironmentType, setActiveEnvironmentType] =
    useLocalStorage<EnvironmentEnum>(
      ACTIVE_ENV,
      DEFAULT_ENV,
      validateActiveEnv,
    );
  const { activeProjectId } = useDashboardContext();
  const [environments, setEnvironments] =
    useState<EnvironmentsResponseEnvironments>();

  const { subscription } = useSubscriptionContext();

  useGetEnvironmentsByProjectId(
    {
      projectId: activeProjectId ?? '', // query will be triggered only when activeProjectId is truthy
    },
    {
      enabled: Boolean(activeProjectId),
      onError: (error) => {
        logger.error(error);
      },
      onSuccess: (response) => {
        if (!response.environments) return;

        setEnvironments(response.environments);
      },
    },
  );

  useEffect(() => {
    if (
      activeEnvironmentType === EnvironmentEnum.Live &&
      subscription?.shouldLockEnvironmentSwitch &&
      subscription.version === '2'
    ) {
      setActiveEnvironmentType(EnvironmentEnum.Sandbox);
    }
  }, [activeEnvironmentType, subscription, setActiveEnvironmentType]);

  const value = useMemo(
    () => ({
      activeEnvironment: environments?.[activeEnvironmentType],
      activeEnvironmentType,
      docsUrl: ROUTES.documentation.toString(),
      environments,
      setActiveEnvironmentType,
    }),
    [environments, activeEnvironmentType, setActiveEnvironmentType],
  );

  return (
    <EnvironmentsContext.Provider value={value}>
      {children}
    </EnvironmentsContext.Provider>
  );
};

export const useEnvironmentsContext = () => {
  const context = useContext(EnvironmentsContext);

  if (context === undefined) {
    throw new Error(
      'usage of useEnvironmentsContext not wrapped in `EnvironmentsContextProvider`.',
    );
  }

  return context;
};
