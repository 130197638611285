import React from 'react';

import { Paper } from '../Paper';

import styles from './EmptyPaper.module.css';

export const EmptyPaper = () => (
  <Paper>
    <div className={styles['empty-heading']} />
    <div className={styles['empty-paragraph']} />
    <div className={styles['empty-paragraph']} />
  </Paper>
);
