import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { AlertProps, Section } from '@dynamic-labs/northstar';
import { ProviderEnum } from '@dynamic-labs/sdk-api';
import { useCors } from '@dynamic-labs/redcoast-query';

import { useEnvironmentsContext } from '../../../../../../app/context/EnvironmentsContext';
import { useProvidersContext } from '../../../../../../app/context/ProvidersContext';
import { ROUTES } from '../../../../../../app/components/Navigation/data';

import styles from './SocialSection.module.scss';
import { SocialToggle } from './SocialToggle';

type SocialSectionProps = {
  disabled: boolean;
};

export const SocialSection: FC<SocialSectionProps> = ({ disabled }) => {
  const { t } = useTranslation();

  const { availableSocialProviders } = useProvidersContext();
  const { enableTelegram } = useFlags();

  const useAvailableSocialProvider = !enableTelegram
    ? availableSocialProviders.filter(
        (provider) => provider !== ProviderEnum.Telegram,
      )
    : availableSocialProviders;

  const { activeEnvironment } = useEnvironmentsContext();
  const { origins: originData } = useCors({
    environmentId: activeEnvironment?.id ?? '',
  });
  const navigation = useNavigate();

  const missingCorsOrigins =
    originData === undefined ||
    originData?.origins === undefined ||
    originData.origins.length === 0;

  const missingCorsOriginsAlert: AlertProps = {
    action: {
      onClick: () => navigation(ROUTES.security),
      text: t(
        'v2.page.embedded_wallets.dynamic_section.embedded_wallet.turnkey.alert.cors.action',
      ),
    },
    description: t(
      'v2.page.embedded_wallets.dynamic_section.embedded_wallet.turnkey.alert.cors.description',
    ),
    title: t(
      'v2.page.embedded_wallets.dynamic_section.embedded_wallet.turnkey.alert.cors.title',
    ),
    variant: 'warning',
  };

  return (
    <Section
      title={t('v2.page.log_in_methods.social.title')}
      description={t('v2.page.log_in_methods.social.description')}
      alerts={missingCorsOrigins ? [missingCorsOriginsAlert] : undefined}
      alertsAlignment='top'
    >
      <div className={styles.section}>
        {useAvailableSocialProvider.map((provider) => (
          <SocialToggle
            key={provider}
            provider={provider}
            disabled={missingCorsOrigins || disabled}
          />
        ))}
      </div>
    </Section>
  );
};
