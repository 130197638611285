import { Provider } from '@dynamic-labs/sdk-api';

/**
 * Determines if the given providers have changed based on their `enabledAt` and `clientId` properties.
 *
 * @param {Provider} provider - The current provider.
 * @param {Provider} originalProvider - The previously saved provider.
 * @returns {boolean} Returns `true` if the providers have changed, otherwise `false`.
 */
export const didProviderChange = (
  provider: Provider,
  originalProvider: Provider,
): boolean =>
  Boolean(provider.enabledAt) !== Boolean(originalProvider.enabledAt) ||
  provider.clientId !== originalProvider.clientId;
