import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { Section } from '@dynamic-labs/northstar';

import { OnSignupToggle } from './OnSignupToggle';
import { ShowEmbeddedWalletActionsUiToggle } from './ShowEmbeddedWalletActionsUiToggle';
import styles from './SettingsRow.module.scss';

type SettingsRowProps = {
  disabled: boolean;
};

export const SettingsRow: FC<SettingsRowProps> = ({ disabled }) => {
  const { t } = useTranslation();

  return (
    <Section
      title={t('v2.page.embedded_wallets.dynamic_section.settings.title')}
      description={t(
        'v2.page.embedded_wallets.dynamic_section.settings.description',
      )}
    >
      <div className={styles.wrapper}>
        <OnSignupToggle disabled={disabled} />
        <ShowEmbeddedWalletActionsUiToggle disabled={disabled} />
      </div>
    </Section>
  );
};
