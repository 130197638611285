// eslint-disable-next-line import/no-namespace
import * as Tabs from '@radix-ui/react-tabs';
import { t } from 'i18next';

import { CheckIcon } from '@dynamic-labs/sdk-react-core';

import { SingleKey } from '../../../../../components/SingleKey';
import Button from '../../../../../components/Button';
import styles from '../../../../../components/Accordion/Accordion.module.css';

export const InstallSdkStep = ({
  onStepCompleted,
}: {
  onStepCompleted: (step: number) => void;
}) => (
  <div className={styles['stepper-tabs']}>
    <Tabs.Root defaultValue='npm'>
      <Tabs.List>
        <Tabs.Trigger className={styles['tab-trigger']} value='npm'>
          NPM
        </Tabs.Trigger>
        <Tabs.Trigger className={styles['tab-trigger']} value='yarn'>
          Yarn
        </Tabs.Trigger>
      </Tabs.List>
      <Tabs.Content className={styles['tab-content']} value='npm'>
        <SingleKey
          keyValue='npm install viem @dynamic-labs/sdk-react-core @dynamic-labs/ethereum'
          className={styles['single-key']}
        />
      </Tabs.Content>
      <Tabs.Content className={styles['tab-content']} value='yarn'>
        <SingleKey
          keyValue='yarn add viem @dynamic-labs/sdk-react-core @dynamic-labs/ethereum'
          className={styles['single-key']}
        />
      </Tabs.Content>
    </Tabs.Root>
    <Button
      variant='secondary'
      leading={<CheckIcon />}
      onClick={() => onStepCompleted(1)}
      testId='done-cta'
    >
      {t<string>('overview.steps.done_button')}
    </Button>
  </div>
);
