import React from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ChainEnum, ProjectSettingsChains } from '@dynamic-labs/sdk-api';

import { useEnvironmentsContext } from '../../../../../context/EnvironmentsContext';
import { useSettingsContext } from '../../../../../context/SettingsContext';
import { ChainName } from '../../../../../components/ChainName';
import { Toggle } from '../../../../../components/Toggle';
import { Typography } from '../../../../../components/Typography';
import Checkbox from '../../../../../components/Checkbox';
import { Tooltip } from '../../../../../components/Tooltip';
import { ROUTES } from '../../../../../components/Navigation/data';
import { ModalHashLocations } from '../../../utils';

type Props = {
  enabled: boolean;
  isInitialEnabled: boolean;
};

type ChainConfiguration = {
  enabled: boolean;
  name: string;
  primary: boolean;
};

// references:
// https://www.iana.org/assignments/jwt/jwt.xhtml#claims
// https://github.com/jaredhanson/id-blockchain-claims-in-jwt/blob/main/draft.md
export const ChainNames = {
  ALGO: 'algorand',
  BTC: 'bip122', // see: https://namespaces.chainagnostic.org/bip122/caip2
  COSMOS: 'cosmos',
  ETH: 'evm',
  EVM: 'evm',
  FLOW: 'flow',
  SOL: 'solana',
  STARK: 'starknet',
};

export const EmbeddedWalletChainConfigurationSection = ({
  enabled,
  isInitialEnabled,
}: Props) => {
  const { t } = useTranslation();
  const { activeEnvironmentType } = useEnvironmentsContext();
  const { setSettings, settings, initialSettings } = useSettingsContext();

  const chainConfigurations =
    settings[activeEnvironmentType]?.sdk?.embeddedWallets?.chainConfigurations;

  const isChainEnabledGlobally = (chainName: ChainEnum): boolean => {
    const foundChain = settings[activeEnvironmentType]?.chains.find(
      (chain: ProjectSettingsChains) => chain.name === ChainNames[chainName],
    );

    return Boolean(foundChain?.enabled);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const ewChainConfiguration = JSON.parse(
      JSON.stringify(
        settings[activeEnvironmentType]?.sdk?.embeddedWallets
          ?.chainConfigurations,
      ),
    );
    const chainConfigToUpdate = ewChainConfiguration?.find(
      (config: ChainConfiguration) => config.name === event.target.id,
    );

    chainConfigToUpdate.enabled =
      event.target.checked &&
      isChainEnabledGlobally(event.target.id as ChainEnum);

    // if this was previously the primary chain, make it no longer the primary.
    if (!event.target.checked && chainConfigToUpdate.primary) {
      const otherConfig = ewChainConfiguration?.filter(
        (c: ChainConfiguration) => c.name !== chainConfigToUpdate.name,
      )[0];
      if (otherConfig) {
        otherConfig.primary = true;
      }
      chainConfigToUpdate.primary = false;
    }

    setSettings({
      ...settings,
      [activeEnvironmentType]: {
        ...settings[activeEnvironmentType],
        sdk: {
          ...settings[activeEnvironmentType].sdk,
          embeddedWallets: {
            ...settings[activeEnvironmentType].sdk.embeddedWallets,
            chainConfigurations: ewChainConfiguration,
          },
        },
      },
    });
  };

  const renderChainToggle = (chain: ChainConfiguration) => {
    // at least one chain needs to be enabled if embedded wallets is enabled
    const isOnlyChainEnabled =
      chainConfigurations
        ?.filter((c) => c.name !== chain.name)
        .every((c) => !c.enabled) && chain.enabled;
    const toggleDisabled =
      isOnlyChainEnabled ||
      !enabled ||
      !isChainEnabledGlobally(chain.name as ChainEnum);

    let tooltipText = '';
    if (!isChainEnabledGlobally(chain.name as ChainEnum)) {
      tooltipText = t(
        'integrations.wallets.providers.turnkey.chain_config.toggle.tooltip.chain_not_enabled_globally',
      );
    } else if (isOnlyChainEnabled) {
      tooltipText = t(
        'integrations.wallets.providers.turnkey.chain_config.toggle.tooltip.last_chain_enabled',
      );
    }

    const primaryCheckboxDisabled = !chain.enabled || chain.primary;

    const chainSettingSaved = Boolean(
      initialSettings[
        activeEnvironmentType
      ]?.sdk?.embeddedWallets?.chainConfigurations?.find(
        (c: ChainConfiguration) => c.name === chain.name && c.enabled,
      ),
    );

    const isChainSettingSaved =
      Boolean(chainSettingSaved === chain.enabled) && isInitialEnabled;

    return (
      <div
        className='flex items-center justify-between p-3 border border-cloud-2 w-full rounded-lg'
        key={chain.name}
      >
        <div className='flex items-center'>
          <Tooltip
            tooltipText={[tooltipText]}
            disableHover={!toggleDisabled || !tooltipText || !enabled}
            placement='top'
          >
            <Toggle
              id={chain.name}
              ariaLabel={`embedded-wallet-chain-${chain.name}`}
              handleChange={handleChange}
              checked={
                chain.enabled &&
                isChainEnabledGlobally(chain.name as ChainEnum) &&
                enabled
              }
              disabled={toggleDisabled}
              withIcon
              variant={isChainSettingSaved ? 'success' : 'primary'}
            />
          </Tooltip>
          <ChainName name={chain.name} />

          {!isChainEnabledGlobally(chain.name as ChainEnum) && (
            <Link
              to={`${ROUTES.chainsAndNetworks}#${
                chain.name === ChainEnum.Sol
                  ? ModalHashLocations.Solana
                  : ModalHashLocations.Evm
              }`}
              className='ml-4'
            >
              <Typography
                variant='paragraph-2'
                weight='bold'
                color='primary-1'
                className='flex items-center gap-1'
              >
                {t(
                  'integrations.wallets.providers.turnkey.chain_config.toggle.enable_chain',
                )}
              </Typography>
            </Link>
          )}
        </div>
        <div>
          <Checkbox
            label={t(
              'integrations.wallets.providers.turnkey.chain_config.toggle.label',
            )}
            id={`${chain.name}-primary-chain`}
            name='set-primary-chain'
            onChange={handlePrimaryChainChange}
            checked={chain.primary}
            value={chain.name}
            disabled={primaryCheckboxDisabled}
          />
        </div>
      </div>
    );
  };
  const handlePrimaryChainChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const ewChainConfiguration = JSON.parse(
      JSON.stringify(
        settings[activeEnvironmentType]?.sdk?.embeddedWallets
          ?.chainConfigurations,
      ),
    );
    const chainConfigToUpdate = ewChainConfiguration?.find(
      (config: ChainConfiguration) => config.name === event.target.value,
    );

    if (
      event.target.checked &&
      isChainEnabledGlobally(event.target.value as ChainEnum)
    ) {
      // toggle off prev primary
      ewChainConfiguration?.forEach((c: ChainConfiguration) => {
        c.primary = false;
      });
      chainConfigToUpdate.primary = true;
    } else {
      // toggle on the other chain to primary
      const otherConfig = ewChainConfiguration?.filter(
        (c: ChainConfiguration) => c.name !== chainConfigToUpdate.name,
      )[0];
      if (otherConfig) {
        otherConfig.primary = true;
      }
      chainConfigToUpdate.primary = false;
    }

    setSettings({
      ...settings,
      [activeEnvironmentType]: {
        ...settings[activeEnvironmentType],
        sdk: {
          ...settings[activeEnvironmentType].sdk,
          embeddedWallets: {
            ...settings[activeEnvironmentType].sdk.embeddedWallets,
            chainConfigurations: ewChainConfiguration,
          },
        },
      },
    });
  };

  return (
    <div>
      <Typography variant='paragraph-2' weight='bold'>
        {t('integrations.wallets.providers.turnkey.chain_config.title')}
      </Typography>
      <div className='flex flex-col gap-2 pt-4'>
        {settings[
          activeEnvironmentType
        ]?.sdk?.embeddedWallets?.chainConfigurations?.map(renderChainToggle)}
      </div>
    </div>
  );
};
