import { FC, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import {
  Alert,
  BooleanInput,
  Button,
  Input,
  SingleToggleCard,
  Typography,
} from '@dynamic-labs/northstar';
import {
  CustomField,
  CustomFieldRequest,
  CustomFieldType,
  CustomFieldValidValue,
} from '@dynamic-labs/sdk-api';

import { logger } from '../../../../../../../../app/services/logger';

import styles from './NewFieldSideDrawer.module.scss';
import { DropdownCustomField } from './DropdownCustomField';
import { CheckboxCustomField } from './CheckboxCustomField';
import { RegexCustomField } from './RegexCustomField';

type NewFieldSideDrawerProps = {
  disabled: boolean;
  field: CustomFieldRequest | CustomField;
  onChange: (field: CustomFieldRequest | CustomField) => void;
  onClose: () => void;
  onDelete: () => void | Promise<void>;
  onSave: () => Promise<void>;
};

const toggleFields = ['required'];

export const NewFieldSideDrawer: FC<NewFieldSideDrawerProps> = ({
  field,
  disabled,
  onClose,
  onSave,
  onDelete,
  onChange,
}) => {
  const { t } = useTranslation();
  const [currentField, setCurrentField] = useState<
    CustomFieldRequest | CustomField
  >(field);
  const [initialField] = useState<CustomFieldRequest | CustomField>(field);
  const [loading, setLoading] = useState(false);
  const [settingsHasChanged, setSettingsHasChanged] = useState(false);
  const [errors, setErrors] = useState('');
  const [activateErrors, setActivateErrors] = useState(false);
  const [invalidPosition, setInvalidPosition] = useState<number | undefined>(
    undefined,
  );

  useEffect(() => {
    setCurrentField(field);
  }, [field]);

  const handleInputValue = (
    value:
      | string
      | CustomFieldType
      | boolean
      | CustomFieldValidValue[]
      | number,
    key: keyof CustomFieldRequest,
  ) => {
    let updatedField = { ...currentField, [key]: value };

    if (key === 'fieldType') {
      if (value !== CustomFieldType.Checkbox) {
        updatedField = {
          ...updatedField,
          validationRules: {
            ...updatedField.validationRules,
            checkboxText: undefined,
          },
        };
      }
      if (value !== CustomFieldType.Select) {
        updatedField = {
          ...updatedField,
          validationRules: {
            ...updatedField.validationRules,
            validOptions: undefined,
          },
        };
      }
      if (value !== CustomFieldType.Text) {
        updatedField = {
          ...updatedField,
          validationRules: {
            ...updatedField.validationRules,
            regex: undefined,
            unique: undefined,
          },
        };
      }
    }

    setCurrentField(updatedField);
    setSettingsHasChanged(true);
    onChange(updatedField);
  };

  const handleOptionsChange = (options: CustomFieldValidValue[]) => {
    const updatedField = {
      ...currentField,
      validationRules: {
        ...currentField.validationRules,
        validOptions: options,
      },
    };
    setCurrentField(updatedField);
    setSettingsHasChanged(true);
    onChange(updatedField);
  };

  const handleCheckboxTextChange = (checkboxText: string) => {
    const updatedField = {
      ...currentField,
      validationRules: { ...currentField.validationRules, checkboxText },
    };
    setCurrentField(updatedField);
    setSettingsHasChanged(true);
    onChange(updatedField);
  };

  const handleRegexChange = (regex: string) => {
    const updatedField = {
      ...currentField,
      validationRules: { ...currentField.validationRules, regex },
    };
    setCurrentField(updatedField);
    setSettingsHasChanged(true);
    onChange(updatedField);
  };

  const handleToggleUnique = (unique: boolean) => {
    const updatedField = {
      ...currentField,
      validationRules: { ...currentField.validationRules, unique },
    };
    setCurrentField(updatedField);
    setSettingsHasChanged(true);
    onChange(updatedField);
  };

  const handleSave = async () => {
    setActivateErrors(true);
    if (
      field.validationRules?.regex !== '' &&
      field.validationRules?.regex !== undefined
    ) {
      try {
        RegExp(field.validationRules?.regex);
      } catch (e) {
        setErrors('Invalid Regex');
        return;
      }
    }
    setLoading(true);
    try {
      await onSave();
      setSettingsHasChanged(false);
      setErrors('');
      setActivateErrors(false);
      onClose();
    } catch (e: any) {
      if (e.message !== 'Unexpected server error.') {
        setErrors(e.message);
        setInvalidPosition(currentField.position);
      } else {
        logger.error(e);
        setErrors(
          t('v2.page.log_in_methods.kyc.custom_fields.side_drawer.error'),
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteField = async () => {
    await onDelete();
    setCurrentField(field);
    setSettingsHasChanged(false);
    onClose();
  };

  const fieldTypes = [
    {
      label: t(
        'v2.page.log_in_methods.kyc.custom_fields.side_drawer.type.input_field',
      ),
      type: CustomFieldType.Text,
    },
    {
      label: t(
        'v2.page.log_in_methods.kyc.custom_fields.side_drawer.type.checkbox',
      ),
      type: CustomFieldType.Checkbox,
    },
    {
      label: t(
        'v2.page.log_in_methods.kyc.custom_fields.side_drawer.type.dropdown',
      ),
      type: CustomFieldType.Select,
    },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.section}>
        {errors && (
          <Alert
            title={t(
              'v2.page.log_in_methods.kyc.custom_fields.side_drawer.error_title',
            )}
            description={errors}
            variant='error'
          />
        )}
        <Typography variant='paragraph-3' weight='medium' color='gray-1'>
          {t('v2.page.log_in_methods.kyc.custom_fields.side_drawer.type.title')}
        </Typography>
        {fieldTypes.map((fieldType) => (
          <BooleanInput
            key={fieldType.type}
            checked={currentField.fieldType === fieldType.type}
            id={`select-${fieldType.type}`}
            onChange={() => handleInputValue(fieldType.type, 'fieldType')}
            type='radio'
            isSaved
            label={fieldType.label}
            disabled={disabled}
          />
        ))}
      </div>
      <div className={styles.input_section}>
        <Typography variant='paragraph-3' weight='medium' color='gray-1'>
          {t('v2.page.log_in_methods.kyc.custom_fields.side_drawer.name.title')}
        </Typography>
        <Input
          name='fieldName'
          label={
            currentField.name === '' || undefined
              ? t(
                  'v2.page.log_in_methods.kyc.custom_fields.side_drawer.name.label',
                )
              : currentField.name
          }
          onChange={(e) => handleInputValue(e.target.value, 'name')}
          value={currentField.name}
          disabled={disabled}
          error={activateErrors && (currentField.name === '' || undefined)}
        />

        <Typography variant='paragraph-3' weight='medium' color='gray-1'>
          {t(
            'v2.page.log_in_methods.kyc.custom_fields.side_drawer.position.title',
          )}
        </Typography>
        <Input
          type='number'
          name='fieldPosition'
          value={currentField.position}
          label={t(
            'v2.page.log_in_methods.kyc.custom_fields.side_drawer.position.label',
          )}
          onChange={(e) =>
            handleInputValue(parseInt(e.target.value, 10), 'position')
          }
          disabled={disabled}
          className={styles.position}
          error={
            activateErrors &&
            (currentField.position < 0 ||
              (errors ===
                'Posiiton must be between 0 and the amount of Custom Fields' &&
                invalidPosition !== undefined &&
                currentField.position >= invalidPosition))
          }
        />

        {currentField.fieldType === CustomFieldType.Select && (
          <DropdownCustomField
            field={currentField}
            disabled={disabled}
            onOptionsChange={handleOptionsChange}
            activateErrors={activateErrors}
          />
        )}
        {currentField.fieldType === CustomFieldType.Checkbox && (
          <CheckboxCustomField
            field={currentField}
            disabled={disabled}
            onCheckboxTextChange={handleCheckboxTextChange}
            activateErrors={activateErrors}
          />
        )}
        {currentField.fieldType === CustomFieldType.Text && (
          <RegexCustomField
            field={currentField}
            disabled={disabled}
            onRegexChange={handleRegexChange}
            activateErrors={activateErrors}
          />
        )}
      </div>
      <div className={styles.toggle_section}>
        <Typography variant='paragraph-3' weight='medium' color='gray-1'>
          {t(
            'v2.page.log_in_methods.kyc.custom_fields.side_drawer.settings.title',
          )}
        </Typography>
        {currentField.fieldType === CustomFieldType.Text &&
          (!('id' in field) || initialField.validationRules?.unique) && (
            <SingleToggleCard
              key='unique'
              accordionKey={`unique-toggle-${field.name}`}
              title={t(
                'v2.page.log_in_methods.kyc.custom_fields.side_drawer.unique.title',
              )}
              description={t(
                'v2.page.log_in_methods.kyc.custom_fields.side_drawer.unique.description',
              )}
              allowExpand={false}
              inputProps={{
                checked: currentField.validationRules?.unique ?? false,
                disabled,
                id: `kyc-prop-toggle-unique-${field.name}`,
                isSaved:
                  'id' in field &&
                  currentField.validationRules?.unique ===
                    initialField.validationRules?.unique,
                onChange: (e) => handleToggleUnique(e.target.checked),
                type: 'toggle',
              }}
            />
          )}
        {toggleFields.map((toggleField) => (
          <SingleToggleCard
            key={toggleField}
            accordionKey={`required-toggle-${field.name}`}
            title={
              toggleField === 'required'
                ? t(
                    `v2.page.log_in_methods.kyc.custom_fields.side_drawer.${toggleField}.title`,
                  )
                : undefined
            }
            description={
              toggleField === 'required'
                ? t(
                    `v2.page.log_in_methods.kyc.custom_fields.side_drawer.${toggleField}.description`,
                  )
                : undefined
            }
            allowExpand={false}
            inputProps={{
              checked: currentField[
                toggleField as keyof CustomFieldRequest
              ] as boolean,
              disabled,
              id: `kyc-prop-toggle-${toggleField}-${field.name}`,
              isSaved:
                'id' in field &&
                currentField[toggleField as keyof CustomFieldRequest] ===
                  initialField[toggleField as keyof CustomFieldRequest],
              onChange: (e) =>
                handleInputValue(
                  e.target.checked,
                  toggleField as keyof CustomFieldRequest,
                ),
              type: 'toggle',
            }}
          />
        ))}
        <div className={styles.button_container}>
          <div className={styles.delete_button}>
            <Button
              dataTestId='delete_new_field_button'
              variant='secondary'
              size='large'
              onClick={handleDeleteField}
              disabled={disabled}
              text={t(
                'v2.page.log_in_methods.kyc.custom_fields.side_drawer.delete',
              )}
            />
          </div>
          <div className={styles.save_button}>
            <Button
              dataTestId='saveChanged'
              variant='primary'
              size='large'
              onClick={handleSave}
              disabled={disabled || !settingsHasChanged}
              text={t(
                'v2.page.log_in_methods.kyc.custom_fields.side_drawer.save',
              )}
              isLoading={loading}
              className={styles.save_button}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
