import { useState } from 'react';

import { t } from 'i18next';

import { logger } from '../../../services/logger';
import Portal from '../../../components/Portal';
import Header from '../../../components/Portal/Header';
import { projectsApi } from '../../../services/api';
import Button from '../../../components/Button';
import { ErrorInfo } from '../../../components/ErrorInfo';
import { Typography } from '../../../components/Typography';

import styles from './DeleteProjectModal.module.css';

type UpdateProjectModalProps = {
  onClose(): void;
  projectId: string;
};

export const DeleteProjectModal = ({
  onClose,
  projectId,
}: UpdateProjectModalProps) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const handleDeleteClick = async () => {
    setLoading(true);
    try {
      await projectsApi.deleteProjectById({
        projectId,
      });
      setLoading(false);
      onClose();
    } catch (e) {
      setLoading(false);
      setErrorMessage(
        t('admin.delete_project_modal.error_has_active_live_users'),
      );
      logger.error(e);
    }
  };

  return (
    <Portal handleClose={onClose} className={styles.portal}>
      <Header
        className={styles.header}
        handleClose={onClose}
        headingText={t<string>('delete_project.title')}
      />
      <div className='text-sm text-gray-1 font-medium'>
        {t<string>('delete_project.text')}
      </div>
      {errorMessage && (
        <ErrorInfo className='mb-5'>
          <span>
            <Typography
              className={styles.errorHeading}
              variant='paragraph-2'
              weight='medium'
            >
              {t<string>('admin.delete_project_modal.error')}
            </Typography>
            <Typography className={styles.errorMessage} variant='paragraph-1'>
              {errorMessage}
            </Typography>
          </span>
        </ErrorInfo>
      )}
      <div className={styles.buttons}>
        <Button
          variant='secondary'
          type='button'
          disabled={loading}
          onClick={onClose}
        >
          {t<string>('actions.cancel')}
        </Button>
        <Button
          className={styles.deleteButton}
          type='button'
          variant='secondary'
          loading={loading}
          onClick={handleDeleteClick}
        >
          {t<string>('actions.delete')}
        </Button>
      </div>
    </Portal>
  );
};
