import { Dispatch, SetStateAction } from 'react';

import { t } from 'i18next';

import Portal from '../../../../components/Portal';
import Header from '../../../../components/Portal/Header';
import { WebhookForm } from '../WebhookForm';
import { Typography } from '../../../../components/Typography';

import styles from './CreateWebhookModal.module.css';

export const CreateWebhookModal = ({
  setShowModal,
}: {
  setShowModal: Dispatch<SetStateAction<boolean>>;
}) => (
  <Portal handleClose={() => setShowModal(false)} className={styles.portal}>
    <Header headingText={t('webhooks.create_webhook_modal.title')} />
    <Typography variant='paragraph-1' className='-mt-3 mb-4'>
      {t<string>('webhooks.create_webhook_modal.subtitle')}
      <a
        href={t('webhooks.docs_url')}
        target='_blank'
        className='text-primary-1'
        rel='noreferrer'
      >
        docs.
      </a>
    </Typography>
    <WebhookForm setShowModal={setShowModal} />
  </Portal>
);
