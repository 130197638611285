import { FC } from 'react';

import { AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { useUpdateProjectSettings } from '@dynamic-labs/redcoast-query';
import { SubscriptionAdvancedScopeEnum } from '@dynamic-labs/sdk-api';

import { SaveBanner, MissingSettingsModal } from '../../../../components';
import { useEnvironmentsContext } from '../../../../../app/context/EnvironmentsContext';
import { useOriginsContext } from '../../../../../app/context/OriginsContext';
import { useSubscriptionLock } from '../../../../../app/hooks/useSubscriptionLock';
import { useSaveProvidersSettings } from '../../../../hooks';

import { SettingsRow } from './SettingsRow';
import { EmbeddedWalletRow } from './EmbeddedWalletRow';
import { MFARow } from './MFARow';
import { useSaveDynamicSectionProvider } from './useSaveDynamicSectionProvider';
import styles from './DynamicSection.module.scss';

type DynamicSectionProps = {
  refetchWalletsBreakdown: VoidFunction;
};

export const DynamicSection: FC<DynamicSectionProps> = ({
  refetchWalletsBreakdown,
}) => {
  const { t } = useTranslation();
  const {
    shouldShowSaveBanner,
    handleSave,
    handleReset,
    shouldShowMissingSettingsWarning,
    setShouldShowMissingSettingsWarning,
    missingSettings,
  } = useSaveDynamicSectionProvider();
  const { enableEmbeddedWalletSessionKeyRegistration } = useFlags();

  const { activeEnvironmentType } = useEnvironmentsContext();
  const { originData } = useOriginsContext();
  const { shouldLockOnLive } = useSubscriptionLock(
    SubscriptionAdvancedScopeEnum.SigninWithEmail,
  );

  const { isLoading: isSavingProjectSettings } = useUpdateProjectSettings();

  const { isLoading: isSavingProviderSettings } = useSaveProvidersSettings();

  const noCorsOrigin =
    (!originData || originData.length === 0) &&
    activeEnvironmentType === 'live';

  const disabled = shouldLockOnLive || noCorsOrigin;

  const isLoading = isSavingProjectSettings || isSavingProviderSettings;

  return (
    <>
      <div className={styles.wrapper}>
        <EmbeddedWalletRow
          disabled={disabled}
          refetchWalletsBreakdown={refetchWalletsBreakdown}
        />

        <SettingsRow disabled={disabled} />

        {/* Show MFA row only if silent signing for user is disabled */}
        {!enableEmbeddedWalletSessionKeyRegistration && (
          <MFARow disabled={disabled} />
        )}
      </div>

      <AnimatePresence mode='wait'>
        {shouldShowSaveBanner && (
          <SaveBanner
            handleOnSave={handleSave}
            handleOnCancel={handleReset}
            isLoading={isLoading}
          />
        )}
      </AnimatePresence>

      <AnimatePresence mode='wait'>
        {shouldShowMissingSettingsWarning && (
          <MissingSettingsModal
            handleClose={() => setShouldShowMissingSettingsWarning(false)}
            missingSettings={missingSettings}
            title={t(
              'v2.page.embedded_wallets.dynamic_section.missing_settings.title',
            )}
            description={t(
              'v2.page.embedded_wallets.dynamic_section.missing_settings.description',
            )}
          />
        )}
      </AnimatePresence>
    </>
  );
};
