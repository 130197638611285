import { ReactElement, ReactNode, useMemo } from 'react';

import classNames from 'classnames';

import Dropdown from '../Dropdown';
import { Typography } from '../Typography';
import { DropdownIcon } from '../../../icons';

import styles from './DropdownField.module.css';

export type SelectOption<T> = { icon?: ReactElement; label: string; value: T };

type Props<T> = {
  className?: string;
  disabled?: boolean;
  error?: boolean;
  onSelect: (value: T) => void;
  options?: SelectOption<T>[];
  placeholder?: ReactNode;
  value?: T;
};

// eslint-disable-next-line @typescript-eslint/comma-dangle
export const DropdownField = <T,>({
  className,
  options,
  value,
  placeholder,
  onSelect,
  disabled = false,
  error = false,
}: Props<T>) => {
  const optionElements = useMemo(
    () =>
      options?.map((opt) => ({
        component: (
          <div className={styles.option}>
            {opt.icon}
            <Typography
              variant='paragraph-2'
              weight='medium'
              as='span'
              className={styles.content}
            >
              {opt.label}
            </Typography>
          </div>
        ),
        isSelected: opt.value === value,
        value: opt.value,
      })) ?? [],
    [options, value],
  );

  const renderPlaceholder = () => (
    <Typography
      variant='paragraph-1'
      weight='regular'
      as='span'
      className={classNames(styles.content, styles.placeholder)}
    >
      {placeholder}
    </Typography>
  );

  const currentContent =
    optionElements.find((elem) => elem.value === value)?.component ??
    renderPlaceholder();

  return (
    <Dropdown className={className}>
      <Dropdown.Control
        disabled={disabled}
        className={classNames(styles.control, {
          [styles.disabled]: disabled,
          [styles.error]: error,
        })}
      >
        {currentContent}
        <DropdownIcon className='!ml-auto w-3 h-3' />
      </Dropdown.Control>
      <Dropdown.List
        containerClassNames={styles['list-container']}
        className={styles.list}
      >
        {optionElements.map(({ component, value: optValue, isSelected }) => (
          <Dropdown.Item
            key={JSON.stringify(optValue)}
            active={isSelected}
            onClick={() => onSelect(optValue)}
            className={classNames(styles['list--item'], {
              [styles.selected]: isSelected,
            })}
          >
            {component}
          </Dropdown.Item>
        ))}
      </Dropdown.List>
    </Dropdown>
  );
};
